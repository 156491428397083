<template>
  <div class="flex-start flex-align-items-center">
    <div class="mr-half">
      <svg-icon-component icon="circle-information" class="icon-sm info" />
    </div>
    <div class="text-left grow">
      <span class="mr-half">
        {{ t(`NotificationsComponent.${licenseCategoryName}UpgradeInfo`) }}
      </span>
      <button id="btnManageAllstateFromNotification" type="button" class="btn-primary mt-half" @click="manageAccount()">
        {{ t("NotificationsComponent.AllstateUpgradeInfoBtn") }}
      </button>
    </div>
    <div>
      <button
        id="btnDismissAllstateUpgradeNotification"
        type="button"
        class="btn-link mr-quarter"
        @click="dismissAllstateUpgradeNotification()"
      >
        <svg-icon-component icon="times" class="icon-xs info" />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, PropType } from "vue";
import { t } from "@/i18n";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { getUrl } from "@/common/getUrl";
import { dismissAllstateUpgradeNotification } from "@/components/Allstate/AllstateHelper";
import { INotification } from "@/stores/notifications";

const props = defineProps({
  notification: {
    type: Object as PropType<INotification>,
    default: null,
  },
});

const licenseCategoryName = ref<string>(props.notification?.params?.licenseCategoryName ?? "");

function manageAccount() {
  dismissAllstateUpgradeNotification();
  window.open(getUrl("ALLSTATE_MANAGE_URL"), "_blank");
}
</script>
