<template>
  <ModalWrapperComponent
    dialog-name="InPlaceCdlpRefundConfirmationComponent"
    :show-dialog="props.showDialog"
    @close-modal="close"
  >
    <template #header>
      <div>
        {{ tn("header") }}
      </div>
    </template>
    <template #content>
      <p class="mb">
        {{ tn("line1") }}
      </p>
    </template>
    <template #footer>
      <div class="text-right">
        <button id="btnRefundPurchase" type="button" class="btn-outline-primary mr" @click="refundPurchase()">
          {{ tn("refundPurchase") }}
        </button>
        <button id="btnKeepPurchase" type="button" class="btn-primary" @click="close()">
          {{ tn("keepPurchase") }}
        </button>
      </div>
    </template>
  </ModalWrapperComponent>
</template>

<script setup lang="ts">
import { t } from "@/i18n";
import ModalWrapperComponent from "@/components/shared/dialogs/ModalWrapper.vue";
import { logEvent } from "@/common/logger";

const componentName = "InPlaceCdlpRefundConfirmationComponent";

const props = defineProps({
  showDialog: {
    type: Boolean,
    default: true,
  },
});

const emits = defineEmits(["close-modal", "refund-purchase"]);
logEvent("created", componentName);

function close() {
  emits("close-modal");
}

function refundPurchase() {
  emits("refund-purchase");
  close();
}

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}
</script>
