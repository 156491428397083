<template>
  <section v-show="!isLoading" class="constrain-65 mb">
    <div v-if="showAllstateAvailabilityMessage">
      <span class="danger-text">{{ tn("allStateAvailabilityMessage") }}</span>
    </div>
    <div id="zuora_payment" class="zuora-hosted-page" :class="classObject"></div>
  </section>
  <section>
    <div class="mb-double">
      <ShippingForm v-if="FLAGS.ENABLE_BUYFLOW_WEBROOT && buyFlowStore.hasShippableItem" />
      <slot name="afterIframe"></slot>
      <slot name="cancelButton"></slot>
      <div v-if="captchaSiteKey" class="captcha mb">
        <vue-recaptcha
          :key="captchaComponentKey"
          :sitekey="captchaSiteKey"
          :load-recaptcha-script="false"
          :language="recaptchaLang"
          @verify="captchaCallback"
          @expired="onCaptchaExpired"
        />
      </div>
      <button
        v-if="props.showSubmitBtn"
        :id="`${formSubmitButtonText ? 'btnReviewOrder' : 'btnSubmit'}`"
        type="submit"
        class="btn-primary submit"
        :disabled="
          isSubmitting ||
          disableBtn ||
          buyFlowStore.isCalculatingCart ||
          props.checkoutDisable ||
          buyFlowStore.hasAlreadyHaveAllstateError
        "
        @click="submit()"
      >
        <spinner :is-spinning="isSubmitting" />
        {{ formSubmitButtonText ? formSubmitButtonText : tn("next") }}
      </button>
    </div>
  </section>
</template>

<script setup lang="ts">
import { t } from "@/i18n";
import { CASTLE_PK, CASTLE_API } from "@/define";
import { ref, PropType, onMounted, onUnmounted, watch, computed } from "vue";
import { logEvent, logException } from "@/common/logger";
import { IZuoraCreditCardFormRequest } from "@/common/api/unifiedPortal/interfaces";
import { ICreditCardAddress } from "@/components/Buy/Interfaces";
import * as creditCardFunctions from "@/components/CreditCards/CreditCards";
import { FLAGS, RateLimitCreditCardError } from "@/define";
import { useBuyFlowStore } from "@/stores/buyFlow";
import { useUserStore } from "@/stores/user";
import { VueRecaptcha } from "vue-recaptcha";
import { useConfigStore } from "@/stores/config";
import Spinner from "@/components/shared/Spinner.vue";
import { useNotificationsStore } from "@/stores/notifications";
import ShippingForm from "@/components/Buy/Payment/ShippingForm.vue";
import { checkShippingFormForErrors } from "@/components/Buy/BuyHelper";
import * as Castle from "@castleio/castle-js";
import { openTextBrands } from "../Buy/BuyEnums";

const componentName = "AddPaymentMethodComponent";

const props = defineProps({
  checkoutDisable: {
    type: Boolean,
  },
  formSubmitButtonText: {
    type: String,
    default: "",
  },
  userGuid: {
    type: String,
    required: false,
    default: "",
  },
  email: {
    type: String,
    required: false,
    default: "",
  },
  computerId: {
    type: Number,
    required: false,
    default: 0,
  },
  licenseKey: {
    type: String,
    required: false,
    default: "",
  },
  enableCaptcha: {
    type: Boolean,
    required: false,
    default: false,
  },
  prePopulateForm: {
    type: Object as PropType<ICreditCardAddress>,
    required: false,
    default: null,
  },
  showSubmitBtn: {
    type: Boolean,
    default: true,
  },
  brand: {
    type: String as PropType<openTextBrands>,
    default: openTextBrands.Carbonite,
  },
});

const emits = defineEmits(["submit", "payment-method-added", "payment-method-error"]);

const castleOptions = {
  pk: CASTLE_PK,
  apiUrl: CASTLE_API,
} as Castle.ConfigureOptions;

Castle.configure(castleOptions);

logEvent("created", componentName);
const isLoading = ref<boolean>(true);
const zuoraIFrameRequest = ref<IZuoraCreditCardFormRequest>();
const isSubmitting = ref<boolean>(false);
const iFrameLoadError = ref<boolean>(false);
const iFrameErrors = ref<boolean>(false);
const disableBtn = ref<boolean>(false);
const buyFlowStore = useBuyFlowStore();
const userStore = useUserStore();
const captchaComponentKey = ref<number>(0);
const captchaSiteKey = ref<string>("");
const enforceCaptcha = ref<boolean>(false);
const recaptchaLang = ref<string>(localStorage.getItem("selectedLanguage") || "en");
const configStore = useConfigStore();
const captchaResponseToken = ref<string>("");
const notificationsStore = useNotificationsStore();
const formIsLoading = ref<boolean>(false);
const showAllstateAvailabilityMessage = ref<boolean>(false);
const castleRequestToken = ref<string>("");

const classObject = computed(() => ({
  "iframe-default": !iFrameLoadError.value,
  "iframe-errors": iFrameErrors.value,
}));

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

function onCaptchaExpired() {
  logEvent("Captcha expired", `${componentName}/onCaptchaExpired`);
  captchaResponseToken.value = "";

  // incrementing the key forces the captcha component to reload
  captchaComponentKey.value += 1;
}

function captchaCallback(responseToken: string) {
  logEvent(`Captcha token: ${responseToken}`, `${componentName}/captchaCallback`);
  captchaResponseToken.value = responseToken;
}

onMounted(async () => {
  try {
    logEvent("mounting", componentName);
    showAllstateAvailabilityMessage.value = false;
    iFrameErrors.value = false;
    iFrameLoadError.value = false;
    isLoading.value = false;
    isSubmitting.value = false;
    disableBtn.value = false;

    castleRequestToken.value = await Castle.createRequestToken();

    //Used to prevent form double loading
    if (!formIsLoading.value) {
      await renderForm();
    }

    if (props.enableCaptcha) {
      captchaSiteKey.value = configStore.config?.captcha?.siteKey ?? "";
      enforceCaptcha.value = configStore.config?.captcha?.enforce ?? false;
    }
    isLoading.value = false;
  } catch (err) {
    handleApiError(err);
  }
});

async function populateForm() {
  try {
    zuoraIFrameRequest.value = (await creditCardFunctions.getCreditCardFormParams(
      props.userGuid,
      props.email,
      props.computerId,
      castleRequestToken.value,
      props.licenseKey,
      props.brand
    )) as IZuoraCreditCardFormRequest;

    const currentPrePopulateForm = props.prePopulateForm || {};
    if (buyFlowStore.cart != undefined) {
      buyFlowStore.cart.zuoraAccountId = zuoraIFrameRequest.value.field_accountId;
      if (buyFlowStore.hasUsOnly) {
        currentPrePopulateForm.creditCardCountry = "USA";
        zuoraIFrameRequest.value.countryWhiteList = "USA";
        showAllstateAvailabilityMessage.value = true;
      }
    }

    if (zuoraIFrameRequest.value) {
      /* eslint-disable */
      //@ts-ignore
      Z.renderWithErrorHandler(zuoraIFrameRequest.value, currentPrePopulateForm, null, errorHandler);
      /* eslint-enable */
    } else {
      logEvent("AddPaymentMethod: attempted to load payment form failed to get request");
    }
  } catch (err) {
    handleApiError(err);
  } finally {
    isLoading.value = false;
  }
}

const cartItemRemoved = computed(() => buyFlowStore.lastCartItemRemoved);
watch(cartItemRemoved, async () => {
  showAllstateAvailabilityMessage.value = false;
  await populateForm();
});

async function renderForm() {
  await populateForm();
  // these are being removed to make sure they are not successes from a different page
  window.document.removeEventListener("ZUORA_POST_SUCCESS", zuoraCreditCardFormSuccessHandler);
  window.document.removeEventListener("ZUORA_POST_FAILURE", zuoraCreditCardFormFailureHandler);
  window.document.addEventListener("ZUORA_POST_SUCCESS", zuoraCreditCardFormSuccessHandler);
  window.document.addEventListener("ZUORA_POST_FAILURE", zuoraCreditCardFormFailureHandler);

  formIsLoading.value = false;
}

onUnmounted(() => {
  logEvent("unmounted", componentName);
  window.document.removeEventListener("ZUORA_POST_SUCCESS", zuoraCreditCardFormSuccessHandler);
  window.document.removeEventListener("ZUORA_POST_FAILURE", zuoraCreditCardFormFailureHandler);
});

const selectedLanguage = computed(() => {
  return userStore.selectedLanguage;
});

watch(selectedLanguage, async () => {
  try {
    zuoraIFrameRequest.value = (await creditCardFunctions.getCreditCardFormParams(
      props.userGuid,
      props.email,
      props.computerId,
      castleRequestToken.value
    )) as IZuoraCreditCardFormRequest;
    zuoraIFrameRequest.value.locale = "en";
    recaptchaLang.value = "en";
    if (userStore.selectedLanguage !== "en") {
      zuoraIFrameRequest.value.locale = "ja";
      recaptchaLang.value = "ja";
    }

    const currentPrePopulateForm = props.prePopulateForm || {};
    if (buyFlowStore.cart != undefined) {
      buyFlowStore.cart.zuoraAccountId = zuoraIFrameRequest.value.field_accountId;
      if (buyFlowStore.hasUsOnly) {
        currentPrePopulateForm.creditCardCountry = "USA";
        zuoraIFrameRequest.value.countryWhiteList = "USA";
        showAllstateAvailabilityMessage.value = true;
      }
    }

    /* eslint-disable */
    //@ts-ignore
    Z.renderWithErrorHandler(zuoraIFrameRequest.value,  currentPrePopulateForm, null, errorHandler);
    /* eslint-enable */
  } catch (err) {
    handleApiError(err);
  }
});

function handleApiError(err: unknown) {
  if ((err as string) === RateLimitCreditCardError) {
    iFrameLoadError.value = true;
    disableBtn.value = true;
    emits("payment-method-error", "RateLimitCreditCardError");
  } else {
    emits("payment-method-error", err);
    //Need to change to logException because this catch was overwriting the one from creditCardFunctions.getCreditCardFormParams
    //where we are putting up the notification instead of redirecting
    logException(err as Error, componentName);
  }
}

async function zuoraCreditCardFormSuccessHandler(event: unknown) {
  emits("payment-method-added", event, true);
}

async function zuoraCreditCardFormFailureHandler(error) {
  isSubmitting.value = false;
  buyFlowStore.showProcessingOrderMessage = false;

  if (typeof error === "string") {
    //if the error we get back from the form failure is a string
    if (error === "AddCreditCardFailed") {
      //then treat the AddCreditCardFailed string as a special case
      emits("payment-method-error", error);
    } else {
      //otherwise, it is just a server issue
      emits("payment-method-error", "ServerErrors");
      //but log it so we can see what it was if we want to handle it differently in the future
      logEvent("zuoraCreditCardFormFailureHandler", componentName, error);
    }
  } else {
    //if we get back an object, then we'll stay here because we don't want to allow the user to send this again
    notificationsStore.clearNotifications();

    if (error.detail.errorMessage.indexOf("Insufficient Funds") !== -1) {
      //treat insufficient funds with a special string
      notificationsStore.addNotification({ type: "CreditCardInsufficientFunds" });
    } else if (error.detail.errorMessage.indexOf("Expiration date") !== -1) {
      //say something about the invalid expiration date as well
      notificationsStore.addNotification({ type: "CreditCardInvalidExpirationDate" });
    } else {
      //otherwise, it is just a server issue
      notificationsStore.addNotification({ type: "ServerErrors" });
      //but log it so we can see what it was if we want to handle it differently in the future
      logEvent("zuoraCreditCardFormFailureHandler", componentName, error);
      logException({ name: "zuoraCreditCardFormFailureHandler", message: "unknown Zuora iFrame error message" });
    }

    //Used to prevent form double loading
    if (!formIsLoading.value) {
      formIsLoading.value = true;
      await renderForm();
    }
  }
}

function errorHandler(key, code, message) {
  /* eslint-disable */
  //@ts-ignore
  Z.sendErrorMessageToHpm(key, message);
  /* eslint-enable */
  buyFlowStore.checkSalesTax = false;
  iFrameErrors.value = true;
  isSubmitting.value = false;
  buyFlowStore.showProcessingOrderMessage = false;
}

function submit() {
  if (FLAGS.ENABLE_BUYFLOW_WEBROOT && !buyFlowStore.shippingMatchesBilling && buyFlowStore.hasShippableItem) {
    //If there are shipping form errors stop
    if (checkShippingFormForErrors()) {
      return;
    }

    //No errors, set shipping address on cart
    if (buyFlowStore.cart) {
      buyFlowStore.cart.shippingAddress = buyFlowStore.shippingAddress;
    }
  }

  if (!buyFlowStore.checkSalesTax) {
    buyFlowStore.showProcessingOrderMessage = true;
  }
  emits("submit", "Add Credit Card Submit");
}

watch(
  () => buyFlowStore.$state.checkSalesTax,
  checkSalesTax => {
    if (checkSalesTax) {
      submit();
    }
  }
);
</script>

<style scoped lang="css">
@import "@/styles/variables.css";

.add-credit-card {
  /* used in CreditCard/AddCreditCard.vue and courier/CourierRecovery.vue
  both of which call AddPaymentMethodComponent */
  display: flex;
  flex-direction: column;
  padding: var(--space);
}

@media (min-width: 960px) {
  .add-credit-card {
    display: block;
  }
}
</style>
