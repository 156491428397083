<template>
  <TileWrapper
    v-show="displayTile"
    :key="td.status"
    :is-loading="isLoading"
    :tile-data="td"
    notification-context="AllStateOverviewComponent"
  >
    <template #title>{{ tn(`${td.title}`) }}</template>
    <template #content>
      <TileDetails>
        <template #content>
          <div v-if="showCompareOptions">
            <p><SvgIconComponent icon="logo-allstate" /></p>
            <p>{{ tn(`zeroStateP1`) }}</p>
            <p v-if="showAllstateFamilyText()" id="allstateFamilyExtraText">{{ tn(`zeroStateBestBuyTTSB`) }}</p>
            <p>
              <small>{{ tn(`zeroStateP2`) }}</small>
            </p>
            <button id="compareOptionsBtn" class="btn-outline-primary" type="button" @click="openAllstateTab()">
              {{ tn(`compareOptionsBtn`) }}
            </button>
          </div>

          <div v-else-if="showLearnMore">
            <p><SvgIconComponent icon="logo-allstate" /></p>
            <p>{{ tn(`zeroStateP1`) }}</p>
            <p>
              <small>{{ tn(`zeroStateP2`) }}</small>
            </p>
            <button id="learnMoreBtn" class="btn-outline-primary" type="button" @click="buyAllState()">
              {{ tn(`compareOptionsBtn`) }}
            </button>
          </div>

          <div v-else-if="showCreateAccountInactive">
            <p><SvgIconComponent icon="logo-allstate" /></p>
            <p class="mb">{{ tn(`premiumCreateAccountP1`) }}</p>
            <div class="side-by-side">
              <button id="createAccountAllStateBtn" class="btn-primary no-shrink mr-half" type="button" disabled="true">
                {{ tn(`createAccountBtn`) }}
              </button>
              <small class="text-sm">
                {{ tn(`inactiveDisclaimer`) }}
              </small>
            </div>
          </div>

          <div v-else-if="showCreateAccountDanger">
            <p>
              <span class="danger-text">
                <strong>{{ t(`HomeComponent.dangerDetails`) }}</strong>
              </span>
              {{ tn("attentionOnCreate") }}
            </p>
            <p><SvgIconComponent icon="logo-allstate" /></p>
            <button id="createAccountAllStateBtn" class="btn-primary" type="button" @click="goToCreateAccountForm()">
              {{ tn(`createAccountBtn`) }}
            </button>
          </div>

          <div v-else-if="showManageAccount">
            <p><SvgIconComponent icon="logo-allstate" /></p>
            <p class="mb">{{ tn(`manageAccountP1`) }}</p>
            <button
              id="manageAccountAllStateBtn"
              :class="manageAccountAllStateBtnClass"
              type="button"
              @click="manageAccount()"
            >
              {{ tn(`goToIDPBtn`) }}
            </button>

            <div
              v-if="
                displayUpgradeSection && canDisplayGlobalFeature(globalFeatures.allStateUpgrade) && !inUnderMaintenance
              "
            >
              <hr class="mt" />
              <p class="mb-half">
                <strong>{{ t(`Common.extendYourProtection`) }}</strong>
              </p>
              <p class="mb">{{ t(`AllstateAlreadySignedUpComponent.subIncludesIdentityProtection`) }}</p>
              <button id="viewDetailButton" class="btn-link left-link" type="button" @click="goToUpgradeOptions()">
                {{ t(`Common.viewDetails`) }}
              </button>
            </div>
          </div>

          <div v-else-if="hasExpired">
            <p><SvgIconComponent icon="logo-allstate" /></p>
            <p class="mb">
              {{ tn(`${hasExpiredPremium ? "premiumExpired" : "addOnExpired"}`) }}
            </p>
            <button
              v-if="!isExpiredBestBuyAllState"
              id="renewNowExpiredBtn"
              class="btn-link"
              type="button"
              @click="renewNow()"
            >
              {{ t(`Common.renewNow`) }}
            </button>
          </div>
        </template>
      </TileDetails>
    </template>
  </TileWrapper>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue";
import { logEvent, logException } from "@/common/logger";
import TileDetails from "@/components/shared/TileDetails.vue";
import TileWrapper from "@/components/shared/TileWrapper.vue";
import { ITileData } from "@/components/Home";
import { TileType, TileTitle, StatusType, StateType, CancellationReasons } from "@/globalEnums";
import { t } from "@/i18n";
import { IAllstateResponse } from "@/common/api/unifiedPortal/interfaces";
import { useSubscriptionsStore } from "@/stores/subscriptions";
import { ISubscription } from "@/common/api/unifiedPortal/interfaces";
import { useRouter } from "vue-router";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { useUserStore } from "@/stores/user";
import { SUBSCRIPTION_LAST_ALLSTATE_DAY, FLAGS, cancelled, SUBSCRIPTION_MIN_EXPIRED_DAY } from "@/define";
import { useAllstateSubscriptionsStore } from "@/stores/allstateSubscriptions";
import { getUrl } from "@/common/getUrl";
import { allstateLCNs, webrootCDLP, webrootLicenseCategories } from "@/common/webrootProductName";
import { retailCodes } from "@/common/featureDisplayAccess";
import { unifiedApi } from "@/common";
import { AxiosError } from "axios";
import { handleApiError } from "@/common/handleApiError";
import {
  hasWebrootSubscriptions,
  hasAllstateSubscriptions,
  hasActivePremiumSubscriptions,
  hasActivePremiumFamilySubscriptions,
  hasInactivePremiumSubscriptions,
  hasAddOnSubscriptions,
  hasAllstateAccountSetup,
  hasExpiredPremiumSubscriptions,
  dismissAllstateUpgradeNotification,
  getUpgradeCartUrl,
  hasExpiredBestBuyAllState,
  hasActiveCDLPSubscriptions,
} from "@/components/Allstate/AllstateHelper";
import { canDisplayGlobalFeature, globalFeatures } from "@/common/featureDisplayAccess";
import { pageStates as buyPageStates } from "@/components/Buy/BuyEnums";
import { hasActiveVPNSubscription } from "@/common/subscriptionHelper";

const name = "AllStateOverviewComponent";

const emits = defineEmits(["tile-ready", "tile-count"]);

logEvent("created", name);
const router = useRouter();
const isLoading = ref(true);
const allstateSubscriptions = ref<IAllstateResponse[]>();
const subscriptions = ref<ISubscription[]>([]);
const subscriptionsStore = useSubscriptionsStore();
const allstateSubscriptionsStore = useAllstateSubscriptionsStore();
const userStore = useUserStore();

const hasWebroot = ref<boolean>(false);
const hasAllstate = ref<boolean>(false);
const hasActivePremium = ref<boolean>(false);
const hasActivePremiumFamily = ref<boolean>(false);
const hasActiveCDLP = ref<boolean>(false);
const hasInactivePremium = ref<boolean>(false);
const hasExpiredPremium = ref<boolean>(false);
const hasAddOn = ref<boolean>(false);
const alreadyHasAccountSetup = ref<boolean>(false);
const isExpiredBestBuyAllState = ref<boolean>(false);

const displayUpgradeSection = computed(
  () => !hasActivePremiumFamily.value && !hasActiveCDLP.value && FLAGS.DISPLAY_ALLSTATE_UPGRADE === "true"
);
const manageAccountAllStateBtnClass = computed(() =>
  displayUpgradeSection.value ? "btn-outline-primary" : "btn-primary"
);

const td = ref<ITileData>({
  title: TileTitle.allStateOverview,
  type: TileType.tile,
  status: StatusType.normal,
  state: StateType.notPurchased,
});

const inUnderMaintenance = computed(() => {
  return userStore.currentUser?.subscriberInfo?.isUnderMaintenance || false;
});

const hasExpired = computed(() => {
  const allstateSubs = subscriptions.value.filter(s => allstateLCNs.includes(s.licenseCategoryName ?? ""));
  if (!allstateSubs || allstateSubs.length === 0) {
    return false;
  }
  return allstateSubs.every(
    l =>
      l.hasOutstandingInvoices ||
      (l.remainingDays <= 0 && l.remainingDays >= SUBSCRIPTION_LAST_ALLSTATE_DAY) ||
      (l.zuoraSubscriptionStatus.toLowerCase() === cancelled &&
        l.cancellationReason !== CancellationReasons.FlatRateUpgradeRefund)
  );
});

const isActiveVPNWithExclusiveExpiredCDLP = computed(() => {
  // if we have a CDLP subscription that has expired but the account has
  // an active VPN subscription we don't show the CDLP expiry
  const allStateSubscriptions = subscriptionsStore.subscriptions.filter(
    s => s.licenseCategoryName && allstateLCNs.includes(s.licenseCategoryName)
  );

  const hasExpiredCDLP = allStateSubscriptions.some(
    s => s.sku && webrootCDLP.includes(s.sku) && s.remainingDays <= SUBSCRIPTION_MIN_EXPIRED_DAY
  );

  if (!hasExpiredCDLP) return false;

  const hasNonCDLPExpired = allStateSubscriptions.some(
    s => s.sku && !webrootCDLP.includes(s.sku) && s.remainingDays <= SUBSCRIPTION_MIN_EXPIRED_DAY
  );

  if (hasNonCDLPExpired) return false;

  const hasActiveVPN = hasActiveVPNSubscription(subscriptionsStore.subscriptions);
  return hasActiveVPN;
});

// Display flags
const showCompareOptions = computed(
  () => hasWebroot.value && (!hasAllstate.value || isActiveVPNWithExclusiveExpiredCDLP.value)
);
const showLearnMore = computed(
  () =>
    !hasWebroot.value && (!hasAllstate.value || isActiveVPNWithExclusiveExpiredCDLP.value) && !hasInactivePremium.value
);
const showCreateAccountInactive = computed(() => hasInactivePremium.value && !alreadyHasAccountSetup.value);
const showCreateAccountDanger = computed(
  () => (hasAddOn.value || hasActivePremium.value) && !alreadyHasAccountSetup.value && !hasExpired.value
);
const showManageAccount = computed(
  () => (hasAddOn.value || hasActivePremium.value) && alreadyHasAccountSetup.value && !hasExpired.value
);

onMounted(async () => {
  try {
    emits("tile-count");
    allstateSubscriptions.value = (await allstateSubscriptionsStore.getAllstateSubscriptions()).allstateSubscriptions;
    await subscriptionsStore.populateSubscriptions();
    subscriptions.value = subscriptionsStore.subscriptions;
    isExpiredBestBuyAllState.value = await hasExpiredBestBuyAllState();
    await loadFlags();

    // Add notification if expired
    td.value.status =
      (hasExpired.value && !isActiveVPNWithExclusiveExpiredCDLP.value) ||
      ((hasAddOn.value || hasActivePremium.value) && !alreadyHasAccountSetup.value)
        ? StatusType.danger
        : StatusType.normal;

    isLoading.value = false;
    emits("tile-ready");
  } catch (err) {
    logException(err as Error);
  }
});

async function loadFlags() {
  hasWebroot.value = await hasWebrootSubscriptions();
  hasAllstate.value = await hasAllstateSubscriptions();
  hasActivePremium.value = await hasActivePremiumSubscriptions();
  hasActivePremiumFamily.value = await hasActivePremiumFamilySubscriptions();
  hasActiveCDLP.value = await hasActiveCDLPSubscriptions();
  hasInactivePremium.value = await hasInactivePremiumSubscriptions();
  hasAddOn.value = await hasAddOnSubscriptions();
  alreadyHasAccountSetup.value = await hasAllstateAccountSetup();
  hasExpiredPremium.value = await hasExpiredPremiumSubscriptions();
}

const webrootUserDistributionTypes = userStore.currentUser?.subscriberInfo?.wrLicenseDistributionMethodCodes;
function showAllstateFamilyText() {
  return FLAGS.DISPLAY_ALLSTATE_UPGRADE === "true" && webrootUserDistributionTypes?.some(l => l === retailCodes.TTSB);
}

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${name}.${v}`, params);
}

function buyAllState() {
  window.location.href = getUrl("ALLSTATE_BUY");
}

function openAllstateTab() {
  router.push("/allstateSignUp");
}

function goToCreateAccountForm() {
  router.push("allstateSignUp");
}

function manageAccount() {
  dismissAllstateUpgradeNotification();
  window.open(getUrl("ALLSTATE_MANAGE_URL"), "_blank");
}

async function renewNow() {
  try {
    if (hasExpired.value) {
      const subscription = subscriptions.value.find(
        s =>
          allstateLCNs.includes(s.licenseCategoryName ?? "") &&
          s.remainingDays <= 0 &&
          s.remainingDays >= SUBSCRIPTION_LAST_ALLSTATE_DAY
      );
      if (subscription) {
        // Offer Webroot Premium to the user if it is WSAIA
        if (subscription.licenseCategoryName === webrootLicenseCategories.WSAIA) {
          router.push({ name: "buy", query: { step: buyPageStates.email, sku: "WebrootPremium" } });
          return;
        }

        if (subscription.keyCode) {
          var url = (await unifiedApi.getWebrootRenewUrl(subscription.keyCode)).data.redirectUrl;
          window.open(url, "_self");
        }
      }
    }
  } catch (err) {
    handleApiError(err as AxiosError, false);
  }
}

async function goToUpgradeOptions() {
  try {
    const keycode = subscriptions.value.find(s => allstateLCNs.includes(s.licenseCategoryName ?? ""))?.keyCode;
    if (keycode) {
      var url = (await unifiedApi.getWebrootRenewUrl(keycode)).data.redirectUrl;
      if (url) {
        window.open(await getUpgradeCartUrl(keycode), "_self");
      }
    }
  } catch (error) {
    handleApiError(error as AxiosError, true);
  }
}

const displayTile = computed(() => userStore.selectedLanguage === "en");
</script>
