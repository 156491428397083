<template>
  <div class="rfa">
    <notifications-component context="FileAccessComponent" />
    <div class="content" @click="hideElementsIfOpen">
      <header>
        <!-- computer name, drive selector, exit button -->
        <div class="border-bottom">
          <div class="mb">
            <button class="inline-btn-link" @click="closeFileAccess">
              <svg-icon-component icon="chevron-left" class="icon-sm mr-quarter" />
              <span>{{ getBackupName() }}</span>
            </button>
            <small v-if="computerName" class="pipe">|</small>
            <span>{{ formatComputerName(computerName) }}</span>
          </div>
          <div class="flex-start">
            <div class="drive-list mr-half">
              <button class="inline-btn-link" @click="toggleDiskSelector">
                <svg-icon-component icon="breadcrumb" class="icon-lg" />
                <svg-icon-component icon="caret-down" class="icon-xs" />
              </button>
              <nav v-if="showDisks">
                <div v-if="isSmb">{{ t("FileAccessComponent.servers") }}</div>
                <ul v-if="isSmb" class="hard-disks">
                  <li v-for="(l, index) in accountComputers" :key="index" @click="selectComputer(l)">
                    <svg-icon-component v-if="l === computerName" icon="checkmark" />
                    <svg-icon-component v-else icon="server" />
                    {{ l }}
                  </li>
                </ul>
                <ul v-else class="hard-disks">
                  <li
                    v-for="d in computer?.HardDisks"
                    :key="d.FileId"
                    :value="d.FileId"
                    @click="updateCurrentDisk(d.FileId)"
                  >
                    <svg-icon-component v-if="d.FileId == currentDisk" icon="checkmark" />
                    <svg-icon-component v-else-if="d.IsSMB" icon="server" />
                    <svg-icon-component v-else icon="drive" />
                    {{ d.Name }}
                  </li>
                </ul>
                <ul class="folders">
                  <li v-if="isSmb && breadcrumbItems.length > 0">{{ t("FileAccessComponent.backupPath") }}</li>
                  <li v-for="(b, index) in breadcrumbItems" :key="b.FileId" class="flex-start">
                    <svg-icon-component v-if="b.IsBackupSet" icon="backup-set" />
                    <svg-icon-component v-if="index > 0" icon="child-directory" />
                    <svg-icon-component v-if="b.IsBackupRun" icon="drive" />
                    <svg-icon-component
                      v-if="b.IsDirectory && index + 1 === breadcrumbItems.length"
                      icon="folder-open"
                    />
                    <svg-icon-component v-else-if="b.IsDirectory" icon="folder" />
                    <button
                      v-if="b.FileId !== breadcrumbItems[breadcrumbItems.length - 1].FileId"
                      class="inline-btn-link"
                      @click="navigateTo(b.FileId, b.FolderItemCount)"
                    >
                      {{ getDisplayName(b) }}
                    </button>
                    <span v-else class="truncate">{{ getDisplayName(b) }}</span>
                  </li>
                </ul>
              </nav>
            </div>
            <h1 class="border-bottom-none truncate-flex-child-wrapper">
              <b class="truncate-flex">{{ currentDisplayDirectory }}</b>
            </h1>
          </div>
        </div>
      </header>
      <loading-spinner-component v-if="isLoading"></loading-spinner-component>
      <div v-else-if="!isRemoteFileAccessEnabled" class="margin-auto constrain-40">
        <div class="text-center mb">
          <svg-icon-component icon="drive-encrypted" />
        </div>
        <p>{{ t("FileAccessComponent.rfaDisabledP1") }}</p>
        <i18n-t keypath="FileAccessComponent.rfaDisabledP2" tag="p" class="mt mb-200" scope="global">
          <template #contactSupport>
            <button class="inline-btn-link" @click="goToSupportPage()">
              {{ t("Common.contactSupport") }}
            </button>
          </template>
        </i18n-t>
      </div>
      <div v-else-if="showFullPageError" class="text-center">
        <!-- if nothing is backed up -->
        <div v-if="fullPageErrorType == FullPageErrorTypes.noData" class="mt mb-200 constrain-40 margin-auto">
          <div>
            <svg-icon-component icon="folder-empty" />
          </div>
          <p>
            {{ t("FileAccessComponent.nothingBackedUp") }}
          </p>
        </div>
        <div v-if="fullPageErrorType == FullPageErrorTypes.folderEmpty" class="mt mb-200 constrain-40 margin-auto">
          <div>
            <svg-icon-component icon="folder-empty" />
          </div>
          <p>
            {{ t("FileAccessComponent.folderEmpty") }}
          </p>
        </div>
        <!-- if drive is user encrypted -->
        <div v-else-if="fullPageErrorType == FullPageErrorTypes.encrypted">
          <div class="mb">
            <svg-icon-component icon="drive-encrypted" />
          </div>
          <div class="text-left constrain margin-auto text-grey mb-double">
            <p>
              {{ t("FileAccessComponent.driveEncrypted1") }}
            </p>
            <p>
              {{ t("FileAccessComponent.driveEncrypted2") }}
            </p>
            <p>
              {{ t("FileAccessComponent.driveEncrypted3a") }}
              &nbsp;
              <a
                href="https://support.carbonite.com/articles/Personal-Windows-Managing-Your-Private-Encryption-Key"
                target="_blank"
              >
                {{ t("FileAccessComponent.driveEncrypted3b") }}
              </a>
            </p>
          </div>
        </div>
        <!-- if sub has expired -->
        <div v-else-if="fullPageErrorType == FullPageErrorTypes.expired">
          <div class="mb">
            <svg-icon-component icon="computer-expired" />
          </div>
          <p class="mb">
            {{ t("FileAccessComponent.subscriptionExpired") }}
          </p>
          <div class="mb-double">
            <button class="btn-primary" @click="handleSubExpired">
              {{ t("FileAccessComponent.btnSubscriptionExpired") }}
            </button>
          </div>
        </div>
        <!-- if some other RFA internal error -->
        <div v-else-if="fullPageErrorType == FullPageErrorTypes.unknown">
          <div class="mb">
            <svg-icon-component icon="folder-empty" />
          </div>
          <p class="mb-double">oops. This is unknown. But it is permanent.</p>
        </div>
      </div>
      <div v-else>
        <div class="opposite-ends mt mb">
          <div class="input-btn-set grow mr-half">
            <!-- search bar -->
            <div class="input-wrapper">
              <input
                id="search-text"
                v-model="searchText"
                :placeholder="searchPlaceholderText"
                type="text"
                :title="searchTitleText"
                :disabled="disableSearching"
              />
              <div class="search-within-servers">
                {{ t("FileAccessComponent.searchNotAvailable") }}
              </div>
            </div>
            <div>
              <button
                id="search-button"
                type="button"
                class="btn-search"
                :title="searchTitleText"
                :disabled="disableSearching"
                @click="handleSearch"
              >
                <svg-icon-component icon="search" />
              </button>
            </div>
          </div>
          <div id="actions-container" class="nowrap">
            <!-- toggle between viewing files in tabular or tile form -->
            <button
              v-if="isTileView"
              id="listview-toggle-button"
              class="inline-btn-link choose-ui mr-quarter selected"
              data-view-type="0"
              title="List View"
              :disabled="isSearching && isSmb"
              @click="setView(ViewTypes.list)"
            >
              <svg-icon-component icon="view-list" />
            </button>
            <button
              v-if="!isTileView"
              id="iconview-toggle-button"
              class="inline-btn-link choose-ui mr-quarter selected"
              data-view-type="1"
              title="Icon View"
              :disabled="isSearching && isSmb"
              @click="setView(ViewTypes.tile)"
            >
              <svg-icon-component icon="view-icon" />
            </button>
            <!-- sort options -->
            <div class="sort-control">
              <button id="sort" type="button" class="inline-btn-link mr-half" @click="toggleShowSortOptions()">
                <svg-icon-component v-if="sortDirection == SortOrder.ascend" icon="sort-az" />
                <svg-icon-component v-else icon="sort-za" />
              </button>
              <!-- add the class "selected" to the active sort <li> to make a checkmark appear -->
              <!-- consumer sort options here-->
              <ul v-if="showSortOptions && !isSmb">
                <li
                  v-for="(opt, i) in sortOptions"
                  :key="i"
                  :class="{ selected: sortField == opt.field && sortDirection == opt.direction }"
                >
                  <button
                    :id="opt.label"
                    type="button"
                    class="inline-btn-link"
                    @click="sortItems(opt.field, opt.direction)"
                  >
                    {{ opt.label }}
                  </button>
                </li>
              </ul>
              <!-- SMB sort options here-->
              <ul v-if="showSortOptions && isSmb">
                <li
                  v-for="(opt, i) in sortOptions"
                  :key="i"
                  :class="{ selected: sortField == opt.field && sortDirection == opt.direction }"
                >
                  <button
                    :id="opt.label"
                    type="button"
                    class="inline-btn-link"
                    @click="sortAndCall(opt.field, opt.direction)"
                  >
                    {{ opt.label }}
                  </button>
                </li>
              </ul>
            </div>
            <!-- download -->
            <button
              id="btn-download-selected-files"
              :disabled="selectionState !== 'success' || fileSelectionLimitReached"
              class="inline-btn-link"
              type="button"
              value="Download"
              title="Download selected files"
              @click="checkSelectedItemsForEncryptedFiles()"
            >
              <spinner :is-spinning="isDownloading" />
              <svg-icon-component v-if="!isDownloading" icon="download-cloud" />
            </button>
          </div>
        </div>
        <!-- notification about currently selected files for downloading -->
        <ul>
          <li v-if="isSelecting" class="notification files-selected" :class="selectionState">
            <svg-icon-component icon="circle-triangle" class="warning icon icon-relative-move mr-quarter" />
            <span>
              {{ selectionInfoText }}
            </span>
            <small class="selection-message">{{ selectionDisplayMessage() }}</small>
          </li>
          <li v-if="isDownloading && !isSmb" class="notification info files-selected">
            <!-- notification about a zip file being prepared for downloading multiple files -->
            <svg-icon-component icon="circle-information" class="icon-sm" />
            <span>
              {{ downloadZipInfoText }}
            </span>
          </li>
          <li v-if="isSearching" class="info notification">
            <!-- info about current search and how to cancel searching -->
            <p>{{ searchResultsText }}</p>
            <button class="inline-btn-link" @click="clearSearch">{{ clearSearchResultsText }}</button>
          </li>
          <li v-if="showEncryptedError" class="notification warning flex-start files-selected">
            <svg-icon-component icon="circle-triangle" class="icon icon-relative-move mr-half" />
            <div>
              {{ t("FileAccessComponent.multipleEncryptedRunsDownloadError1") }}
              <small class="block">{{ t("FileAccessComponent.multipleEncryptedRunsDownloadError2") }}</small>
            </div>
          </li>
          <li v-if="showEmptyFolderError" class="notification warning flex-start files-selected">
            <svg-icon-component icon="circle-triangle" class="icon mr-half" />
            <div>
              {{ t("FileAccessComponent.emptyFolder") }}
            </div>
          </li>
          <li v-if="cssbDownloadError" class="notification warning files-selected">
            <svg-icon-component icon="circle-triangle" class="icon icon-relative-move mr-half" />
            {{ t(getDownloadErrorMessage(cssbDownloadError, false)) }}
          </li>
        </ul>
        <!-- select all -->
        <div class="select-all">
          <input
            id="mst-select-all"
            :checked="isAllSelected"
            type="checkbox"
            class="mr-quarter"
            title="Select all content"
            :disabled="currentItems.some(item => item.IsBackupRun || item.IsBackupSet)"
            @change="selectAllChanged"
          />
          <label for="mst-select-all">{{ selectAllText }}</label>
        </div>
        <!-- list containing files in table-like or tile form -->
        <ul :class="[viewClass, currentItems.some(item => item.IsSMB) ? 'cssb' : '']">
          <li
            v-for="item in currentItems"
            :key="item.FileId"
            :class="{
              directory: item.IsDirectory || item.IsBackupRun,
              file: !item.IsDirectory,
              selected: isSelected(item),
            }"
          >
            <div
              v-if="item.IsSMB && isSearching && item.GroupedSearchItems"
              class="checkbox"
              :title="selectForDownloadTitleText"
            >
              <input
                :disabled="item.IsBackupRun || item.IsBackupSet || (!item.IsDownloadable && !item.IsDirectory)"
                type="checkbox"
                :checked="isSelectedByGroupedItems(item)"
                @click="groupedItemsSelectionChanged($event, item)"
              />
            </div>
            <div v-else class="checkbox" :title="selectForDownloadTitleText">
              <input
                :disabled="
                  item.IsBackupRun ||
                  item.IsBackupSet ||
                  (FLAGS.ENABLE_RESTRICTED_FILES_DOWNLOAD === 'false' && !item.IsDownloadable && !item.IsDirectory)
                "
                type="checkbox"
                :checked="isSelected(item)"
                @click="selectionChanged($event, item)"
              />
            </div>
            <div class="name-size-date truncate">
              <div :class="{ filename: item.IsImage }">
                <img
                  v-if="item.IsImage && isTileView && item.Base64Image"
                  class="icon mr-quarter"
                  :src="item.Base64Image"
                  @click="setPreviewImage(item)"
                />
                <svg-icon-component
                  v-else
                  :icon="
                    getFileExtensionIconName(
                      item.Name,
                      item.IsDirectory,
                      item.IsBackupSet,
                      item.IsBackupRun,
                      item.IsInvalidImage
                    )
                  "
                  class="icon icon-relative-move mr-quarter"
                ></svg-icon-component>
                <button
                  v-if="item.IsDirectory || item.IsBackupSet || item.IsBackupRun"
                  class="inline-btn-link"
                  @click="decideClickAction(item)"
                >
                  <svg-icon-component
                    v-if="item.GroupedSearchItems && item.GroupedSearchItems.length > 0 && item.IsExpanded"
                    icon="chevron-down"
                    class="icon-xs mr-quarter"
                  ></svg-icon-component>
                  <svg-icon-component
                    v-else-if="item.GroupedSearchItems && item.GroupedSearchItems.length > 0 && !item.IsExpanded"
                    icon="chevron-right"
                    class="icon-xs mr-quarter"
                  ></svg-icon-component>
                  {{ getDisplayName(item) }}
                </button>
                <div v-else class="truncate">
                  <span v-if="item.AlertMessage" :class="item.AlertLevel">
                    <svg-icon-component icon="circle-triangle" class="icon-sm mr-quarter warning"></svg-icon-component>
                  </span>

                  <span v-if="!item.IsImage && !isTileView">
                    <button
                      v-if="item.GroupedSearchItems && item.GroupedSearchItems.length > 0"
                      class="inline-btn-link"
                      @click="toggleGroupedItems(item)"
                    >
                      <svg-icon-component
                        v-if="item.IsExpanded"
                        icon="chevron-down"
                        class="icon-xs mr-quarter"
                      ></svg-icon-component>
                      <svg-icon-component
                        v-else-if="!item.IsExpanded"
                        icon="chevron-right"
                        class="icon-xs mr-quarter"
                      ></svg-icon-component>
                      {{ item.Name }}
                    </button>
                    <span v-else>{{ item.Name }}</span>
                  </span>

                  <button v-else-if="item.IsImage" class="inline-btn-link" @click="setPreviewImage(item)">
                    {{ item.Name }}
                  </button>
                  <span v-else>{{ item.Name }}</span>
                </div>
                <small v-if="item.AlertMessage" class="block indent">
                  {{ item.AlertMessage }}
                </small>
              </div>
              <div class="size-date">
                <small v-if="!item.GroupedSearchItems" class="size">
                  {{ convertSize(item.Size, !item.IsDirectory && !item.IsBackupSet) }}
                </small>
                <small v-if="!item.GroupedSearchItems" class="date-modified">
                  {{ getItemSecondaryInfo(item, isTileView) }}
                </small>
                <i18n-t
                  v-if="item.GroupedSearchItems"
                  keypath="FileAccessComponent.availableInRuns"
                  tag="small"
                  scope="global"
                >
                  <template #numberOfResults>
                    {{ item.GroupedSearchItems.length }}
                  </template>
                </i18n-t>
              </div>
              <!--Grouped Search Results-->
              <ul v-if="item.GroupedSearchItems && item.IsExpanded" class="backup-run">
                <li
                  v-for="groupedItem in item.GroupedSearchItems"
                  :key="groupedItem.FileId"
                  class="opposite-ends align-items-center"
                >
                  <div class="flex-start">
                    <div class="mr-half">
                      <input
                        type="checkbox"
                        :checked="isSelected(groupedItem)"
                        @click="selectionChanged($event, groupedItem)"
                      />
                    </div>
                    <div class="mr-half">
                      {{ t("FileAccessComponent.backupRun") }} {{ formatShort24HourTime(groupedItem.ModifiedDate) }}
                    </div>
                    <div class="text-grey">
                      {{ convertSize(groupedItem.Size, !groupedItem.IsDirectory && !groupedItem.IsBackupSet) }}
                    </div>
                  </div>
                  <div class="download inline-marker">
                    <button
                      :disabled="!groupedItem.IsDownloadable && FLAGS.ENABLE_RESTRICTED_FILES_DOWNLOAD === 'false'"
                      class="inline-btn-link download-file"
                      :title="`Download ${groupedItem.Name}`"
                      @click="checkForEncryptedFile(groupedItem)"
                    >
                      <spinner :is-spinning="groupedItem.IsDownloading" />
                      <svg-icon-component v-if="!groupedItem.IsDownloading" icon="download-cloud" />
                    </button>
                  </div>
                </li>
              </ul>
            </div>
            <div class="download inline-marker">
              <button
                v-if="
                  (!item.IsDirectory || isSmb) &&
                  !item.IsBackupSet &&
                  !item.IsBackupRun &&
                  !hasGroupedSearchResults(item)
                "
                :disabled="!item.IsDownloadable && FLAGS.ENABLE_RESTRICTED_FILES_DOWNLOAD === 'false'"
                class="inline-btn-link download-file"
                :title="`Download ${item.Name}`"
                @click="checkForEncryptedFile(item)"
              >
                <spinner :is-spinning="item.IsDownloading" />
                <svg-icon-component v-if="!item.IsDownloading" icon="download-cloud" />
              </button>
            </div>
            <!-- this simulates an expanded backup run.  When implemented, the page will load with <svg-icon-component v-if="!item.IsDownloading" icon="chevron-right" /> to show it is expandable, and will change to chevron-down when expanded -->
          </li>
        </ul>
        <!-- pagination -->
        <div class="opposite-ends-desktop pagination">
          <ul class="pipe">
            <li
              v-for="(page, i) in displayPages"
              :key="i"
              :class="{ selected: page == currentPage }"
              @click="pageSelect(page)"
            >
              {{ page > 0 ? page : "..." }}
            </li>
          </ul>
          <div>
            <select
              v-model="currentPageSize"
              class="select-css"
              :disabled="currentItems.some(item => item.GroupedSearchItems)"
              @change="updateItemsPerPageCSSB()"
            >
              <option v-for="size in pageSizes" :key="size" :value="size">
                {{ t("FileAccessComponent.pagination", { size: size.toString() }) }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <image-preview-component
      :preview-item="previewItem"
      :show-dialog="showPreview"
      :is-downloading="isDownloading"
      @close-modal="showPreview = false"
      @nav="navPreview"
      @download="downloadPreview"
    />
    <PassPhraseDialogComponent
      :show-dialog="showPassPhraseModal"
      :is-multi-download="isMultiDownload"
      :rfa-item="itemToDownload"
      @close-modal="togglePassPhraseModal"
      @save-modal="savePassPhraseModal"
    />
    <DownloadRestrictedFilesComponent
      v-if="showDownloadRestrictedFilesModal"
      :security-phone="securityPhone"
      :show-initial-state="showInitialStateInDownloadRestrictedFiles"
      :has-valid-castle-token="hasValidCastleToken"
      :castle-request-token="castleRequestToken"
      :verification-code-sent-on-email="verificationCodeSentOnEmail"
      :verification-code-sent-on-phone="verificationCodeSentOnPhone"
      @close-modal="showHideRestrictedFilesDownloadDialog(false)"
      @submit-code-success="initiateRestrictedFilesDownload"
      @exclude-restricted-files="initiateRestrictedFilesDownload('')"
      @download-all-files="enableDownloadRestrictedFilesDialog(false)"
    />
  </div>
</template>

<script setup lang="ts">
import { onMounted, onBeforeUnmount, watch, onUnmounted, ref, computed } from "vue";
import { t } from "@/i18n";
import { logEvent, logException } from "@/common/logger";
import { useNotificationsStore } from "@/stores/notifications";
import ImagePreviewComponent from "@/components/FileAccess/ImagePreview.vue";
import Spinner from "@/components/shared/Spinner.vue";
import LoadingSpinnerComponent from "@/components/shared/LoadingSpinner.vue";
import NotificationsComponent from "@/components/shared/Notifications.vue";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { useUserStore } from "@/stores/user";
import { getDeviceInfo } from "@/common/userDetails";
import { componentName, propData, isLoading, showFullPageError, fullPageErrorType, addLocalValues } from "./commonFn";
import { checkHealth, getComputer, getBackupName, initServerComm, requestSessionKey } from "./serverComm";
import {
  initSortOptions,
  showSortOptions,
  sortDirection,
  sortField,
  sortItems,
  sortItemsSmb,
  sortOptions,
  toggleShowSortOptions,
} from "./sortFn";
import { SortOrder } from "./FileAccessEnums";
import { allItems, currentItems, getCurrentPath, hideElementsIfOpen, isSmb } from "./commonFn";
import {
  currentPage,
  currentPageSize,
  currentPageStart,
  displayPages,
  pageLoad,
  pages,
  pageSelect,
  pageSizes,
  storedPageSize,
} from "./paginationFn";
import {
  isAllSelected,
  isSelected,
  isSelecting,
  selectAllChanged,
  selectedItems,
  selectionChanged,
  selectionDisplayMessage,
  isSelectedByGroupedItems,
  groupedItemsSelectionChanged,
  selectionInfoText,
  selectionState,
  showEmptyFolderError,
  selectionInfo,
} from "./selectionFn";
import {
  handleDownload,
  downloadItem,
  isDownloading,
  cssbDownloadError,
  getDownloadErrorMessage,
  showEncryptedError,
} from "./downloadFn";
import { clearSearch, handleSearch, isSearching, searchResultsText, searchText } from "./searchFn";
import { downloadPreview, setPreviewImage, navPreview, previewItem, showPreview } from "./imagePreviewFn";
import {
  breadcrumbItems,
  computer,
  accountComputers,
  currentDirectory,
  currentDisk,
  currentDisplayDirectory,
  selectedDisk,
  showDisks,
  toggleDiskSelector,
} from "./diskFn";
import {
  setView,
  isTileView,
  viewClass,
  clearSearchResultsText,
  downloadZipInfoText,
  searchPlaceholderText,
  searchTitleText,
  selectAllText,
  selectForDownloadTitleText,
  convertSize,
  getFileExtensionIconName,
  getItemSecondaryInfo,
  computerName,
  fileSelectionLimitReached,
} from "./viewFn";
import {
  closeFileAccess,
  calcCurrentDisk,
  calcBreadcrumbs,
  initDirectoryDisplay,
  navigateTo,
  updateCurrentDisk,
  FIRST_BREADCRUMB_COMPONENT_LENGTH,
  navigateToProp,
  getFileAccessRoute,
  hierarchyLevel,
  getComputerContentsRequest,
} from "./navigationFn";
import { startSessionUpdate, endSessionUpdate, TOKEN_REFRESH_INTERVAL } from "./sessionFn";
import { FullPageErrorTypes, ViewTypes } from "./FileAccessEnums";
import { formatComputerName } from "@/common/formatComputerName";
import { clearRefreshFunctions } from "@/components/shared/LanguageSelector/LanguageSelector";
import { IPassPhraseDialogResult, IRfaItem } from "./interfaces";
import { formatShort24HourTime } from "@/common/dateFormat";
import { useRouter } from "vue-router";
import PassPhraseDialogComponent from "@/components/shared/dialogs/FileAccess/PassPhraseDialog.vue";
import { getComputerContents } from "./cssbServerComm";
import { getUrl } from "@/common/getUrl";
import { CASTLE_API, CASTLE_PK, FLAGS } from "@/define";
import { pageStates } from "@/components/Buy/BuyEnums";
import DownloadRestrictedFilesComponent from "@/components/shared/dialogs/DownloadRestrictedFiles/DownloadRestrictedFiles.vue";
import { IUserSecurityPhone } from "@/common/api/unifiedPortal/interfaces";
import { unifiedApi } from "@/common";
import { downloadResponseError } from "./ersoServerComm";
import * as Castle from "@castleio/castle-js";
import { AxiosError } from "axios";
import { handleApiError } from "@/common/handleApiError";

const props = defineProps({
  computerId: {
    type: String,
    required: true,
  },
  path: {
    type: String,
    required: false,
    default: null,
  },
});

logEvent("creating", componentName);

const userStore = useUserStore();
const user = userStore.currentUser;
const isRemoteFileAccessEnabled = ref<boolean>(user?.subscriberInfo?.isRemoteAccessEnabled ?? true);
const router = useRouter();
const showPassPhraseModal = ref<boolean>(false);
const isMultiDownload = ref<boolean>(false);
const hasValidCastleToken = ref<boolean>(false);
const itemToDownload = ref<IRfaItem>();
const showDownloadRestrictedFilesModal = ref<boolean>(false);
const securityPhone = ref<IUserSecurityPhone>();
const phoneNumber = ref("");
const showInitialStateInDownloadRestrictedFiles = ref<boolean>(false);
const verificationCodeSentOnPhone = ref<boolean>(false);
const verificationCodeSentOnEmail = ref<boolean>(false);
const castleRequestToken = ref<string>("");
const castleOptions = {
  pk: CASTLE_PK,
  apiUrl: CASTLE_API,
} as Castle.ConfigureOptions;
Castle.configure(castleOptions);
const notificationsStore = useNotificationsStore();
watch(props, () => {
  propData.value.computerId = props.computerId;
  propData.value.path = props.path;
  navigateToProp();
});

//watches need to stay in setup if they are monitoring an element on the original page
//or the watch will be triggered before everything is initialized
watch(pages, (newPages, oldPages) => {
  //update currentPage so approximately the same data is visible
  const totalItems = allItems.value.length;
  const oldPerPage = Math.floor(totalItems / oldPages);
  const oldTopOfPage = oldPerPage * currentPage.value;

  if (!isSmb.value) currentPage.value = Math.floor(oldTopOfPage / currentPageSize.value);

  if (currentPage.value < 1 || currentPage.value === Infinity) currentPage.value = 1;
  if (isNaN(currentPage.value)) currentPage.value = 1;
  if (currentPage.value > newPages) currentPage.value = newPages;
  pageLoad();
});

watch(currentPage, () => {
  if (currentPage.value < 1) currentPage.value = 1;
});

//We need to call pageLoad for CSSB because the paging is done server side
function updateItemsPerPageCSSB() {
  //Need to remember size after a cross-backup-run search that limits to 10
  storedPageSize.value = currentPageSize.value;
  if (isSmb.value) {
    //CSSB pageStart follows pages like this:
    // for 10 per page: pageStart = 0 for pg 1, pageStart = 10 for pg 2, pageStart = 20 for pg 3...
    // for 25 per page: pageStart = 0 for pg 1, pageStart = 25 for pg 2, pageStart = 50 for pg 3...

    //find what page we should be on
    currentPage.value = Math.floor(currentPageStart.value / currentPageSize.value) + 1;
    //set the pageStart value to the beginning of that page
    currentPageStart.value = (currentPage.value - 1) * currentPageSize.value;
    pageLoad(true);
  }
}

onMounted(async () => {
  try {
    isLoading.value = true;
    localStorage.removeItem("downloadRequests");

    propData.value.computerId = props.computerId;
    propData.value.path = props.path;
    propData.value.computerName = props.computerId;

    initServerComm();
    await requestSessionKey();
    notificationsStore.clearNotifications();
    initSortOptions(isSmb.value);

    //if we got something useful and the rfa server is responding
    if (await checkHealth()) {
      //get information about the computer the user asked about
      computer.value = await getComputer();
      if (computer.value) {
        //if we've received a path
        if (props.path) {
          //navigate to it
          const pathComponents = props.path.split("/");
          let curPath = await calcCurrentDisk(pathComponents);
          if (pathComponents.length >= FIRST_BREADCRUMB_COMPONENT_LENGTH) {
            curPath = await calcBreadcrumbs(pathComponents);
          }
          //display for the user
          await initDirectoryDisplay(curPath, pathComponents[pathComponents.length - 1]);
        } else {
          //arbitrarily, we'll use the first disk as the default (note watcher does the rest of the setup)
          const disk = computer.value.HardDisks[0];
          selectedDisk.value = disk;
          if (!disk && isSmb.value) {
            await initDirectoryDisplay(computer.value.ComputerName);
          } else if (currentDisk.value != disk.FileId) {
            currentDisk.value = disk.FileId;
          } else {
            currentDirectory.value = disk.Name;
            breadcrumbItems.value = [];
            const path = disk.IsBackupSet ? disk.Name : disk?.Path;

            await initDirectoryDisplay(path);
            //replace the current URL with one that includes a path
            if (!isSmb.value) {
              history.replaceState(
                { path: getCurrentPath() },
                "",
                getFileAccessRoute(props.computerId, getCurrentPath())
              );
            }
          }
        }
        //start a timer to get a refreshed session token once in a while
        startSessionUpdate(TOKEN_REFRESH_INTERVAL);
      } else {
        logEvent("RFA server failed to describe computer", componentName, props.computerId);
        isLoading.value = false;
        propData.value.computerId = "";
        propData.value.computerName = "";
        breadcrumbItems.value = [];
        allItems.value = [];
        currentDirectory.value = "";
      }
    } else {
      logEvent("RFA server not responding", componentName);
      notificationsStore.addNotification({ type: "ServerErrors" });
    }
  } catch (err) {
    logException(err as Error);
    notificationsStore.addNotification({ type: "ServerErrors" });
  }
});

onUnmounted(() => {
  clearRefreshFunctions();
});

onBeforeUnmount(() => {
  endSessionUpdate();
});

function getDisplayName(item: IRfaItem) {
  let displayName = item.Name;

  if (item.IsBackupRun) {
    displayName = formatShort24HourTime(item.ModifiedDate);
  }

  return displayName;
}

function goToSupportPage() {
  window.open(getUrl("VITE_APP_CARBONITE_SUPPORT_SITE"), "_blank");
}

function toggleGroupedItems(item: IRfaItem) {
  item.IsExpanded = !item.IsExpanded;
}

function decideClickAction(item: IRfaItem) {
  if (!item.IsDirectory && isSearching.value) {
    toggleGroupedItems(item);
  } else {
    navigateTo(item.FileId, item.FolderItemCount);
  }
}

async function sortAndCall(field, direction) {
  sortItemsSmb(field, direction);
  //build request specifications
  //if statement is here to stay in search when sorting during a search (so it doesn't call getComputerContents during a search)
  if (isSearching.value) {
    handleSearch();
  } else {
    const contentsRequestObject = getComputerContentsRequest(getCurrentPath() as string);
    allItems.value = await getComputerContents(contentsRequestObject);
  }
  addLocalValues();

  //update the display with the new sort order
  currentItems.value = allItems.value.slice(0, currentItems.value.length);
}

function handleSubExpired() {
  const di = getDeviceInfo(parseInt(props.computerId));
  if (di.userId && di.device) {
    let catId = "";
    if (di.device.catId) catId = di.device.catId.toString();

    router.push({
      name: "buy",
      query: {
        step: pageStates.payment,
        deviceId: di.device.deviceId,
        catId: catId,
      },
    });
  }
}

function hasGroupedSearchResults(item: IRfaItem) {
  return item.GroupedSearchItems && item.GroupedSearchItems.length > 0;
}

async function checkSelectedItemsForEncryptedFiles() {
  if (isSmb.value && selectedItems.value.some(l => l.IsItemEncrypted)) {
    isMultiDownload.value = true;
    //Check if there are multiple back up runs that are encrypted
    const uniques = [...new Set(selectedItems.value.map(item => item.UnixTime))];
    if (uniques.length > 1) {
      let encryptedBackupRuns = 0;
      uniques.forEach(unique => {
        if (selectedItems.value.filter(l => l.UnixTime === unique).some(i => i.IsItemEncrypted)) {
          encryptedBackupRuns++;
        }
      });

      //Throw Error for Multiple Encrypted backup runs
      if (encryptedBackupRuns > 1) {
        showEncryptedError.value = true;
      }
    } else {
      //If there are encrypted files open dialog-name
      togglePassPhraseModal();
    }
  } else {
    //If no encrypted files call handleDownload
    if (FLAGS.ENABLE_RESTRICTED_FILES_DOWNLOAD === "true" && selectionInfo.value?.ContainsRestrictedFiles) {
      isDownloading.value = true; // Show loading icon until we send verification code to user
      const deviceTrustToken = getDeviceTrustTokenForDownload();
      if (!deviceTrustToken) {
        await enableDownloadRestrictedFilesDialog();
      } else {
        await handleDownload("", deviceTrustToken);
        if (
          (downloadResponseError.value?.response?.data as { ErrorId?: string })?.ErrorId === "InvalidTrustSessionKey"
        ) {
          sessionStorage.removeItem("DownloadRestrictedFilesToken" + userStore.currentUser?.personId);
          localStorage.removeItem("DownloadRestrictedFilesToken" + userStore.currentUser?.personId);
          await enableDownloadRestrictedFilesDialog();
        }
      }
      isDownloading.value = false;
    } else {
      handleDownload();
    }
  }
}

async function enableDownloadRestrictedFilesDialog(showInitialState = true) {
  if (selectionInfo.value?.ContainsNonRestrictedFiles && showInitialState) {
    showInitialStateInDownloadRestrictedFiles.value = true;
  } else {
    showHideRestrictedFilesDownloadDialog(false);
    await validateCastleTokenForProtectedFiles();
    showInitialStateInDownloadRestrictedFiles.value = false;
    await sendVerificationCode();
  }
  showHideRestrictedFilesDownloadDialog(true);
}

async function sendVerificationCode() {
  try {
    securityPhone.value = (await unifiedApi.getUserSecurityPhone()).data;
    phoneNumber.value = securityPhone.value.phoneNumber;

    // If phone number exists, send code on phone
    if (phoneNumber.value) {
      const response = await unifiedApi.requestVerificationCode(securityPhone.value.verificationMethod);
      if (response.data.securityPhoneOperationCode == "Success") {
        verificationCodeSentOnPhone.value = true;
      }
    } else {
      // send code on email if phone is not present
      castleRequestToken.value = await Castle.createRequestToken();
      const response = await unifiedApi.requestVerificationCodeWithCastleScore("Email", castleRequestToken.value);
      if (response.data.securityPhoneOperationCode == "Success") {
        verificationCodeSentOnEmail.value = true;
      }
      verificationCodeSentOnEmail.value = true;
    }
  } catch (error) {
    handleApiError(error as AxiosError, true);
  }
}
async function validateCastleTokenForProtectedFiles() {
  castleRequestToken.value = await Castle.createRequestToken();
  hasValidCastleToken.value = (await unifiedApi.validateCastleTokenForProtectedFiles(castleRequestToken.value)).data;
}

async function initiateRestrictedFilesDownload(deviceTrustToken: string) {
  showHideRestrictedFilesDownloadDialog(false);
  if (itemToDownload.value) {
    await downloadItem(itemToDownload.value, "", deviceTrustToken);
    itemToDownload.value = undefined;
  } else {
    await handleDownload("", deviceTrustToken);
  }
}

async function checkForEncryptedFile(item: IRfaItem) {
  if (isSmb.value) {
    isMultiDownload.value = false;
    if (item.IsItemEncrypted) {
      itemToDownload.value = item;
      //Open pass phrase modal
      togglePassPhraseModal();
    } else {
      downloadItem(item);
    }
  } else {
    if (
      FLAGS.ENABLE_RESTRICTED_FILES_DOWNLOAD === "true" &&
      ((!item.IsDownloadable && !item.IsDirectory && !item.IsBackupRun && !item.IsBackupSet) ||
        selectionInfo.value?.ContainsRestrictedFiles)
    ) {
      itemToDownload.value = item;
      item.IsDownloading = true; // Show loading icon until we send verification code to user
      const deviceTrustToken = getDeviceTrustTokenForDownload();
      if (!deviceTrustToken) {
        await enableDownloadRestrictedFilesDialog(false);
      } else {
        await downloadItem(item, "", deviceTrustToken);
        if (
          (downloadResponseError.value?.response?.data as { ErrorId?: string })?.ErrorId === "InvalidTrustSessionKey"
        ) {
          sessionStorage.removeItem("DownloadRestrictedFilesToken" + userStore.currentUser?.personId);
          localStorage.removeItem("DownloadRestrictedFilesToken" + userStore.currentUser?.personId);
          await enableDownloadRestrictedFilesDialog();
        }
      }
      item.IsDownloading = false;
    } else {
      downloadItem(item);
    }
  }
}

function getDeviceTrustTokenForDownload() {
  let deviceTrustToken;
  if (sessionStorage.getItem("DownloadRestrictedFilesToken" + userStore.currentUser?.personId)) {
    deviceTrustToken = sessionStorage.getItem("DownloadRestrictedFilesToken" + userStore.currentUser?.personId);
  } else {
    deviceTrustToken = localStorage.getItem("DownloadRestrictedFilesToken" + userStore.currentUser?.personId);
  }
  return deviceTrustToken;
}

function togglePassPhraseModal() {
  showPassPhraseModal.value = !showPassPhraseModal.value;
}

function savePassPhraseModal(result: IPassPhraseDialogResult) {
  if (result.isMultiDownload) {
    handleDownload(result.passPhrase);
  } else {
    downloadItem(result.item, result.passPhrase);
  }
  togglePassPhraseModal();
}

function showHideRestrictedFilesDownloadDialog(isVisible: boolean) {
  showDownloadRestrictedFilesModal.value = isVisible;
  if (!isVisible) {
    verificationCodeSentOnEmail.value = false;
    verificationCodeSentOnPhone.value = false;
  }
}

async function selectComputer(name: string) {
  hierarchyLevel.value = 0;
  initSortOptions(isSmb.value, false);
  //Update route with new Computer
  router.push(`/FileAccess?computerUid=${name}`);
  //Clear BreadCrumbs
  breadcrumbItems.value = [];
  //Full page error may be on so turn off error
  showFullPageError.value = false;
  //Go back to zero state with new computer selected
  await initDirectoryDisplay(name, "...", true);
}

const disableSearching = computed(() => {
  return isSmb.value && currentItems.value.some(l => l.IsBackupSet);
});

const selectedLanguage = computed(() => {
  return userStore.selectedLanguage;
});

watch(selectedLanguage, async () => {
  initSortOptions(isSmb.value);
});
</script>

<style scoped lang="css">
@import "@/styles/variables.css";

.bold {
  font-weight: bold;
}
.selection-message {
  display: block;
  margin-left: var(--space-quarter);
}
.warning .selection-message {
  margin-left: 33px;
}
.inline-btn-link svg {
  fill: var(--link-color);
}
.inline-btn-link[disabled] {
  background-color: transparent;
  border: none;
}
.inline-btn-link[disabled] svg {
  fill: var(--medium-grey);
}
.choose-ui svg {
  fill: var(--grey);
}
.choose-ui.selected svg {
  fill: var(--link-color);
}
.select-all {
  margin: var(--space) 0;
}
.input-wrapper {
  position: relative;
}
.search-within-servers {
  display: none;
  position: absolute;
  top: var(--space);
  left: var(--space-double);
  width: 300px;
  background-color: white;
  border-radius: 7px;
  border: 2px solid var(--grey);
  padding: var(--space-half);
}
.input-wrapper input[disabled] {
  cursor: not-allowed;
}
.input-wrapper input[disabled]:hover + .search-within-servers {
  display: block;
}
@media (min-width: 600px) {
  small {
    font-size: var(--small-text);
  }
}
</style>
