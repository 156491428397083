<template>
  <ModalWrapperComponent
    dialog-name="SetTwoStepVerificationComponent"
    :show-dialog="showDialog"
    @close-modal="close"
    @pre-open="beforeOpen"
  >
    <template v-if="showStepOne" #header>
      {{ tn("h1Heading") }}
    </template>
    <template v-else #header>
      {{ tn("h1Heading2") }}
    </template>

    <template v-if="showStepOne" #content>
      <p>{{ tn("informationP1") }}</p>
      <ul class="form">
        <li class="radio">
          <input
            id="sms"
            v-model="verificationMethod"
            type="radio"
            name="method"
            value="Sms"
            @click="changePrimaryButtonAction(false)"
          />
          <label for="sms">{{ tn("option1") }}</label>
        </li>
        <li class="radio">
          <input
            id="call"
            v-model="verificationMethod"
            type="radio"
            name="method"
            value="Call"
            @click="changePrimaryButtonAction(false)"
          />
          <label for="call">{{ tn("option2") }}</label>
        </li>
        <li v-if="phoneNumber" class="radio">
          <input
            id="turnOff"
            v-model="verificationMethod"
            type="radio"
            name="method"
            value="turnOff"
            @click="changePrimaryButtonAction(true)"
          />
          <label for="turnOff">{{ tn("option3") }}</label>
        </li>
        <li v-if="showPhoneNumber">
          <label for="newPhoneNumber">{{ tn("label1") }}:</label>
          <div class="side-by-side">
            <select id="countryCode" v-model="selectedCountry" class="select-css auto mr-half mb-half-mobile">
              <option v-for="country in countryCodes" :key="country.name" :value="country">
                {{ getCountryDisplayName(country.name) }}
              </option>
            </select>
            <input id="newPhoneNumber" v-model="newPhoneNumber" type="text" :maxlength="MAX_PHONE_LENGTH" />
          </div>
        </li>
      </ul>
      <h3 v-if="showDelete2FAWarning">{{ tn("heading1") }}</h3>
      <p v-if="showDelete2FAWarning">{{ tn("informationP2") }}</p>
      <p v-if="showPhoneNumber" class="text-sm">{{ tn("informationP3") }}</p>
    </template>

    <template v-else #content>
      <div class="verify-2fa">
        <div v-if="!showSendAgainMessage">
          <i18n-t keypath="SetTwoStepVerificationComponent.bodyP1" tag="p" scope="global">
            <template #phone>{{ securityPhoneNumber }}</template>
          </i18n-t>
          <p>{{ tn("bodyP2") }}</p>
        </div>
        <p v-else>
          <button id="linkSendAgain" class="btn-link" @click="sendAgain()">{{ tn("informationP3c") }}</button>
        </p>

        <ul class="form">
          <li>
            <label for="securityCode">{{ tn("labelSecurityCode") }}</label>
            <input id="securityCode" v-model="code" type="text" />
          </li>
        </ul>
        <div v-if="!showSendAgainMessage">
          <p>
            {{ tn("informationP3a") }}
            <button id="linkSendAgain" class="inline-btn-link" @click="sendAgain()">{{ tn("informationP3b") }}</button>
          </p>
        </div>
      </div>
    </template>

    <template v-if="showStepOne" #footer>
      <button id="btnCancelChanges" type="button" class="btn-link mr" @click="close()">{{ tn("cancel") }}</button>
      <button
        v-if="!showDelete2FAWarning"
        id="btnUpdatePhone"
        type="submit"
        class="btn-primary"
        :disabled="!newPhoneNumber || !selectedCountry || !verificationMethod"
        @click="updatePhone()"
      >
        {{ tn("next") }}
      </button>
      <button v-else id="btnDeletePhone" type="submit" class="btn-primary" @click="deletePhone()">
        {{ tn("save") }}
      </button>
    </template>

    <template v-else #footer>
      <button id="btnStep3Skip2FA" type="button" class="btn-outline-primary mr" @click="close()">
        {{ tn("cancel") }}
      </button>
      <button id="btnSaveChanges" type="submit" class="btn-primary" :disabled="nextDisabled" @click="submitCode()">
        {{ tn("submitCode") }}
      </button>
    </template>
  </ModalWrapperComponent>
</template>

<script setup lang="ts">
import { PropType, ref, computed, watch } from "vue";
import { MAX_PHONE_LENGTH, TWO_FACTOR_CODE_LENGTH } from "@/define";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { unifiedApi } from "@/common";
import { IPhoneCountryCodes, IUser, IUserSecurityPhoneRequest } from "@/common/api/unifiedPortal/interfaces";
import { useNotificationsStore, NotificationType } from "@/stores/notifications";
import ModalWrapperComponent from "@/components/shared/dialogs/ModalWrapper.vue";
import { AxiosError } from "axios";
import { handleApiError } from "@/common/handleApiError";

const componentName = "SetTwoStepVerificationComponent";

const props = defineProps({
  phoneNumber: {
    type: String,
    default: "",
  },
  currentUser: {
    type: Object as PropType<IUser | null>,
    required: true,
  },
  isVerified: {
    type: Boolean,
    defaults: false,
  },
  countryCodes: {
    type: Array as PropType<IPhoneCountryCodes[]>,
    required: true,
  },
  showDialog: {
    type: Boolean,
    required: true,
  },
});

const emits = defineEmits(["save-modal", "close-modal"]);

logEvent("created", componentName, props);

const expirationTime = ref(0);
const notificationsStore = useNotificationsStore();
const showSendAgainMessage = ref<boolean>(false);

const verificationMethod = ref<string>("");
const selectedCountry = ref<IPhoneCountryCodes | null>(null);
const newPhoneNumber = ref<string>();
const showStepOne = ref<boolean>(true);
const showFooter = ref<boolean>(true);

const showPhoneNumber = ref<boolean>(false);
const showDelete2FAWarning = ref<boolean>(false);
const email = ref<string>("");
const code = ref<string>("");

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

const nextDisabled = computed(() => {
  return !(code.value.length === TWO_FACTOR_CODE_LENGTH);
});

function changePrimaryButtonAction(isTurnOff: boolean) {
  showDelete2FAWarning.value = isTurnOff;
  showPhoneNumber.value = !isTurnOff;
}

async function updatePhone() {
  notificationsStore.clearNotifications();
  code.value = "";

  const regex = new RegExp("^[0-9-]*$");
  newPhoneNumber.value = newPhoneNumber.value?.replace(/[\s.]/g, "");

  if (!newPhoneNumber.value) {
    displayResultMessage("EmptyPhone");
    return;
  }

  if (!regex.test(newPhoneNumber.value)) {
    displayResultMessage("InvalidPhoneType");
    return;
  }

  if (!selectedCountry.value) {
    displayResultMessage("InvalidCountry");
    return;
  }

  if (!verificationMethod.value) {
    displayResultMessage("InvalidVerificationMethod");
    return;
  }

  const phoneRequest: IUserSecurityPhoneRequest = {
    phoneNumber: newPhoneNumber.value,
    countryCode: selectedCountry.value.primaryCode,
    country: selectedCountry.value.name,
    validationMethod: verificationMethod.value,
  };

  try {
    const response = (await unifiedApi.setSecurityPhone(phoneRequest)).data;
    if (!response.isValid) {
      displayResultMessage(response.securityPhoneOperationCode);
      return;
    }
    showStepOne.value = false;
    showFooter.value = false;
  } catch (err) {
    logEvent("Update Phone Error", componentName, err);
    handleApiError(err as AxiosError, true);
  }
}

function getCountryDisplayName(countryName: string) {
  const country = props.countryCodes.find(c => c.name === countryName);
  if (country) {
    const countryCode = country.codes.split(";");
    const displayName = `${tn(country.name)} ${countryCode[0]}`;
    return displayName;
  }
  return countryName;
}

async function confirmCode(result: string) {
  notificationsStore.clearNotifications();
  if (result === "OK") {
    emits("save-modal", result);
  } else {
    try {
      await unifiedApi.deleteSecurityPhone();
    } catch (err) {
      handleApiError(err as AxiosError, false);
    }
  }
}

function close() {
  emits("close-modal");
}

async function deletePhone() {
  notificationsStore.clearNotifications();
  try {
    await unifiedApi.deleteSecurityPhone();
  } catch (err) {
    handleApiError(err as AxiosError, false);
  }

  emits("save-modal", "DELETED");
}

function displayResultMessage(message: string) {
  const errType = `SetTwoStepVerification${message}` as NotificationType;

  notificationsStore.clearNotifications();
  notificationsStore.addNotification({ type: errType });
}

const securityPhoneNumber = computed(() => {
  return !!newPhoneNumber.value && !!selectedCountry.value
    ? selectedCountry.value.primaryCode + newPhoneNumber.value
    : props.phoneNumber;
});

function beforeOpen() {
  notificationsStore.clearNotifications();
  showStepOne.value = true;
  showFooter.value = true;
  verificationMethod.value = "";
  showDelete2FAWarning.value = false;
  showPhoneNumber.value = false;

  if (!!props.phoneNumber && !props.isVerified) {
    //Strip phone number of area code and special characters
    //Also set the Area code select and the phone number input
    newPhoneNumber.value = props.phoneNumber.split(" ")[1].replace(/\D|-|\s/g, "");
    selectedCountry.value =
      props.countryCodes.find(code => code.primaryCode === props.phoneNumber.split(" ")[0]) || null;
  }
}

async function sendAgain() {
  notificationsStore.clearNotifications();
  showSendAgainMessage.value = false;
  try {
    if (props.currentUser) {
      const response = (await unifiedApi.requestVerificationCode(verificationMethod.value || "Sms")).data;
      expirationTime.value = Date.parse(response.expirationTime);
      notificationsStore.addNotification({ type: "TwoStepVerificationCodeSent" });
    }
  } catch (error) {
    handleApiError(error as AxiosError, false);
  }
}

async function submitCode() {
  notificationsStore.clearNotifications();
  try {
    if (!props.currentUser || code.value.length !== TWO_FACTOR_CODE_LENGTH) {
      throw "CodeInvalid";
    }
    const securityPhoneValidation = (await unifiedApi.sendVerificationCode(props.currentUser.personId, code.value))
      .data;
    if (!securityPhoneValidation.isValid) {
      let notificationMessage: NotificationType = "TwoStepVerificationFailed";
      switch (securityPhoneValidation.securityPhoneOperationCode) {
        case "VerificationWindowExpired":
          showSendAgainMessage.value = true;
          notificationMessage = "SetTwoStepVerificationVerificationWindowExpired";
          break;
        case "TooManyVerificationAttempts":
          notificationMessage = "SetTwoStepVerificationTooManyVerificationAttempts";
          break;
        case "HighRiskScore":
          notificationMessage = "SetTwoStepVerificationHighRiskScore";
          break;
      }
      notificationsStore.addNotification({ type: notificationMessage });
    } else {
      confirmCode("OK");
    }
  } catch (error) {
    handleApiError(error as AxiosError, true, "TwoStepVerificationFailed");
  }
}

//Add watch on this to mimic the mounted event from the old component
watch(showStepOne, async () => {
  if (showStepOne.value) {
    return;
  }

  try {
    if (props.currentUser) {
      email.value = props.currentUser.email;
      logEvent(verificationMethod.value);
      const response = (await unifiedApi.requestVerificationCode(verificationMethod.value || "Sms")).data;
      expirationTime.value = Date.parse(response.expirationTime);
    }
  } catch (error) {
    handleApiError(error as AxiosError, false);
  }
});
</script>

<style scoped lang="css">
@import "@/styles/variables.css";

h3 {
  margin-bottom: var(--space-third);
}
</style>
