<template>
  <section class="card mb-double">
    <header>
      <h3>
        <svg-icon-component icon="webroot-total-protection" class="icon-md icon-relative-move" />
        {{ t(wtpOrWtpfProductName) }}
      </h3>
    </header>
    <article>
      <p>
        <strong>{{ tn("toInstall") }}</strong>
      </p>
      <div v-if="showUpgradeBanner" class="flex-start accent-box-important border-radius">
        <div class="mr-half">
          <svg-icon-component icon="circle-information" class="icon-sm info" />
        </div>
        <div class="text-left">
          <span class="mr-half block">
            <strong>{{ tn("important") }}</strong>
            {{ tn(`WTPImportantMessage`) }}
          </span>
        </div>
      </div>
      <ol class="form tight">
        <i18n-t keypath="WebrootDownloadInstructions.allOthersOl1" tag="li" scope="global">
          <template #myAccount>
            <button id="btnGoToDownloadsAllOthersCard" type="button" class="inline-btn-link" @click="goToDownloads()">
              {{ t("Brand.myAccount") }}
            </button>
          </template>
        </i18n-t>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <li v-html="tn('allOthersOl2')"></li>
        <i18n-t keypath="WebrootDownloadInstructions.WTPol3" tag="li" scope="global">
          <template #productName>
            <strong>{{ t(wtpOrWtpfProductName) }}</strong>
          </template>
        </i18n-t>
        <li>{{ tn("WTPol4") }}</li>
      </ol>
      <ul class="mb">
        <li v-if="fetchKeyCodeState === showKeycodeState.loading">
          <span id="spinnerSpan" class="accent-box-info">
            <spinner :is-spinning="true" />
            {{ tn("keycodeRetrieving") }}
          </span>
        </li>
        <li v-if="fetchKeyCodeState === showKeycodeState.success">
          {{ tn("keycodeSuccess") }}
          <span id="keycodeDisplay" class="accent-box-info">
            <strong>{{ formatWebrootKeycode(purchaseKeyCode) }}</strong>
          </span>
          <!-- commenting this out till keycode work is ready -->
          <button
            id="btnCopyKeycodeAllOthersCard"
            type="button"
            class="btn-link"
            @click="copy(formatWebrootKeycode(purchaseKeyCode))"
          >
            {{ t("Common.copy") }}
          </button>
        </li>
        <li v-if="fetchKeyCodeState === showKeycodeState.failed">
          <span id="keycodeFailed" class="accent-box-info">
            {{ tn("keycodeFailed") }}
          </span>
        </li>
      </ul>
    </article>
  </section>
</template>
<script setup lang="ts">
import { ref, onBeforeMount, computed } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import router from "@/routes";
import { useBuyFlowStore } from "@/stores/buyFlow";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import Spinner from "@/components/shared/Spinner.vue";
import { formatKeycode as formatWebrootKeycode } from "@/common/formatKeycode";
import { showKeycodeState } from "@/components/Buy/BuyEnums";
import { WEBROOT_PC_OPTIMIZER, WEBROOT_TOTAL_PROTECTION, WEBROOT_TOTAL_PROTECTION_FAMILY } from "@/define";
import { ICartItem } from "@/common/api/unifiedPortal/interfaces";
import { getProductDisplayNameBySku } from "@/common/productsBySku";
import { webrootCDLP } from "@/common/webrootProductName";
import { useSubscriptionsStore } from "@/stores/subscriptions";

const filteredItems = ref<ICartItem[]>();
const componentName = "WebrootTotalProtection";
const baseComponentName = "WebrootDownloadInstructions";
const buyFlowStore = useBuyFlowStore();
const subscriptionStore = useSubscriptionsStore();

logEvent("created", componentName);

const fetchKeyCodeState = ref<showKeycodeState>(showKeycodeState.loading);
const purchaseKeyCode = ref<string>("");

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${baseComponentName}.${v}`, params);
}

const showUpgradeBanner = computed(() => {
  const cdlpItemInCart = buyFlowStore.cart?.items.find(l => webrootCDLP.some(k => k === l.sku));

  //new items will not have a license key in the cart
  if (cdlpItemInCart?.licenseKey) {
    //If we have the license key info, it will tell us what the license key was for originally
    if (buyFlowStore.licenseKeyInfo) {
      //Compare the skus from license key info and the cart to see if they are different
      return buyFlowStore.licenseKeyInfo.sku !== cdlpItemInCart?.sku;
    }
  }

  //Check if there are subs in the store. This will mean the user is logged in
  if (subscriptionStore.subscriptions.length > 0) {
    //Find the CDLP sub that matches the license key from the cart item
    const cdlpSub = subscriptionStore.subscriptions.find(l => l.licenseKey === cdlpItemInCart?.licenseKey);

    if (cdlpSub) {
      //Compare the skus from license key info and the cart to see if they are different
      return cdlpSub.sku === cdlpItemInCart?.sku;
    }
  }

  return false;
});

onBeforeMount(() => {
  filteredItems.value = buyFlowStore?.cart?.items.filter(l => l.sku !== WEBROOT_PC_OPTIMIZER);
  var keycode = buyFlowStore.PurchaseKeycodes.find(n => n.sku == filteredItems?.value?.[0].sku)?.keycode;
  if (keycode) {
    purchaseKeyCode.value = keycode;
    fetchKeyCodeState.value = showKeycodeState.success;
  } else {
    fetchKeyCodeState.value = showKeycodeState.failed;
  }
});

const wtpOrWtpfProductName = computed(() => {
  var sku = buyFlowStore?.cart?.items.find(
    i => i.sku === WEBROOT_TOTAL_PROTECTION || i.sku === WEBROOT_TOTAL_PROTECTION_FAMILY
  )?.sku;
  return getProductDisplayNameBySku({ sku: sku, isTrial: false });
});

function goToDownloads() {
  router.push("/downloads");
}

function copy(purchaseKeyCode: string) {
  logEvent("copy keycode", componentName, purchaseKeyCode);
  navigator.clipboard.writeText(purchaseKeyCode);
}
</script>
