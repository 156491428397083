<template>
  <div class="actions">
    <div class="has-tooltip arrow-right">
      <button :id="`computer-options-dropdown${props.tagId}`" type="button" class="btn-link">
        {{ t("CardComputerComponent.computerOptions") }}
        <svg-icon-component icon="caret-down" class="icon-xs" />
      </button>
      <div class="tooltip-area">
        <ul>
          <li v-if="props.actions.displayBuyNow" class="mb-half">
            <button :id="`buyNowDropDown${props.tagId}`" class="inline-btn-link" type="button" @click="buyNow(false)">
              {{ t("CardComputerComponent.buyNow") }}
            </button>
          </li>
          <li v-if="displayMove" class="mb-half">
            <button
              v-if="!props.isCdlp"
              :id="`moveSubscription${props.tagId}`"
              class="inline-btn-link"
              type="button"
              @click="toggleMoveMySubscriptionModal('click')"
            >
              {{ t("CardComputerComponent.moveMySubscription") }}
            </button>
            <button
              v-if="props.isCdlp"
              :id="`moveSubscription${props.tagId}`"
              class="inline-btn-link"
              type="button"
              @click="toggleMoveMySubscriptionModal('click')"
            >
              {{ t("CardComputerComponent.moveMyBackup") }}
            </button>
          </li>
          <li v-if="props.actions.displayReinstall" class="only-show-desktop-block mb-half">
            <button
              v-if="!props.isCdlp"
              :id="`reinstallSafeClient${props.tagId}`"
              class="inline-btn-link"
              type="button"
              @click="reinstallSafeClient(props.isCdlp)"
            >
              {{ t("CardComputerComponent.reinstallCarbonite") }}
            </button>
            <button
              v-if="props.isCdlp"
              :id="`reinstallSafeClient${props.tagId}`"
              class="inline-btn-link"
              type="button"
              @click="reinstallSafeClient(props.isCdlp)"
            >
              {{ t("CardComputerComponent.reinstallCdlp") }}
            </button>
          </li>
          <li
            v-if="
              !props.isCdlp &&
              props.actions.displayRenew &&
              canDisplayPerSubscriptionFeature(
                perSubscriptionFeatures.changePlan,
                props.subscription.carbLicenseDistributionMethodCode
              )
            "
            class="mb-half"
          >
            <button :id="`renewUpgrade${props.tagId}`" class="inline-btn-link" type="button" @click="changePlan()">
              <span>{{ t("CardComputerComponent.changePlan") }}</span>
            </button>
          </li>
          <li
            v-if="
              props.actions.displayChangePlan &&
              canDisplayPerSubscriptionFeature(
                perSubscriptionFeatures.changePlan,
                props.subscription.carbLicenseDistributionMethodCode
              )
            "
            class="mb-half"
          >
            <button :id="`changePlan${props.tagId}`" class="inline-btn-link" type="button" @click="changePlan()">
              {{ t("CardComputerComponent.changePlan") }}
            </button>
          </li>
          <li v-if="displayAddVideo" class="mb-half">
            <button :id="`addVideo${props.tagId}`" class="inline-btn-link" type="button" @click="addVideo()">
              {{ t("CardComputerComponent.addVideo") }}
            </button>
          </li>
          <li v-if="displayAddEncryptionKey" class="mb-half">
            <button
              :id="`addEncryptionKey${props.tagId}`"
              class="inline-btn-link"
              type="button"
              @click="toggleAddEncryptionKeyModal(null)"
            >
              {{ t("CardComputerComponent.addEncryptionKey") }}
            </button>
          </li>
        </ul>
      </div>
    </div>
    <MoveMySubscriptionComponent
      :show-dialog="showMoveMySubscriptionModal"
      :device="device"
      :is-cdlp="props.isCdlp"
      @close-modal="toggleMoveMySubscriptionModal"
      @save-modal="moveSubscription"
    />
    <DownloadProductComponent
      :show-dialog="showDownloadProductModal"
      :activation-code="device.activationCode"
      :device-id="device.deviceId"
      :install-type="typeOfInstall"
      :display-cdlp-reinstall="downloadModalCDLPReinstall"
      @close-modal="toggleDownloadProductModal"
    />
    <AddEncryptionKeyComponent
      :show-dialog="showAddEncryptionKeyModal"
      :computer-id="device.deviceId"
      @close-modal="toggleAddEncryptionKeyModal"
      @save-modal="hideAddEncryptionKey()"
    />
  </div>
</template>
<script setup lang="ts">
import { PropType, ref, onBeforeMount } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { IDevice, ISubscription } from "@/common/api/unifiedPortal/interfaces";
import { unifiedApi } from "@/common/index";
import { useNotificationsStore } from "@/stores/notifications";
import { isWindows, isMac, getOsFamily } from "@/common/browserDetect";
import { ICardComputerConfigComputerDropDownActions } from "./CardComputerConfig";
import MoveMySubscriptionComponent from "@/components/Backup/CardComputer/MoveMySubscription.vue";
import DownloadProductComponent from "@/components/shared/dialogs/DownloadProduct/DownloadProduct.vue";
import AddEncryptionKeyComponent from "@/components/Backup/CardComputer/AddEncryptionKey.vue";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { handleApiError } from "@/common/handleApiError";
import { AxiosError } from "axios";
import { canDisplayPerSubscriptionFeature, perSubscriptionFeatures } from "@/common/featureDisplayAccess";

const componentName = "ComputerDropDownComponent";

const props = defineProps({
  device: {
    type: Object as PropType<IDevice>,
    required: true,
  },
  displayForcedReinstallModal: {
    type: Boolean,
    required: true,
  },
  tagId: {
    type: String,
    required: true,
  },
  actions: {
    type: Object as PropType<ICardComputerConfigComputerDropDownActions>,
    required: true,
  },
  subscription: {
    type: Object as PropType<ISubscription>,
    required: false,
    default: null,
  },
  isCdlp: {
    type: Boolean,
    required: true,
  },
});

const emits = defineEmits(["set-installer-type", "buy-now", "change-plan"]);

logEvent("created", componentName, props);

const notificationsStore = useNotificationsStore();

const displayMove = ref<boolean>(true);
const displayAddVideo = ref<boolean>(false);
const displayAddEncryptionKey = ref<boolean>(false);
const showMoveMySubscriptionModal = ref<boolean>(false);
const showDownloadProductModal = ref<boolean>(false);
const showAddEncryptionKeyModal = ref<boolean>(false);
const downloadModalCDLPReinstall = ref<boolean>(false);
const typeOfInstall = ref<string>("");
const showMoveSubscriptionForReinstall = ref<boolean>(false);
const modalType = ref<string>("");
const oldComputerOs = ref<string>("");
const newComputerOs = ref<string>("");
const isTrial = ref<boolean>(false);

displayMove.value = isWindows() || isMac() ? props.actions.displayMove : false;
displayAddVideo.value = !!props.device.canOptInVideoBackup;
displayAddEncryptionKey.value = !props.device.hasSSLKey;

if (props.displayForcedReinstallModal) {
  reinstallSafeClient(props.isCdlp);
}

onBeforeMount(async () => {
  logEvent("Before Mount", componentName);
});

//Function will show  Move My Subscription when new and old os are not same as well as not trial
function checkMoveSubForReinstallRequired() {
  oldComputerOs.value = props.device.platform.toLowerCase();
  newComputerOs.value = getOsFamily().toLowerCase();
  isTrial.value = props.device.isTrial;
  modalType.value =
    oldComputerOs.value.includes(newComputerOs.value) || newComputerOs.value.includes(oldComputerOs.value)
      ? isWindows()
        ? "windowsTransfer"
        : "macTransfer"
      : isWindows()
        ? "macToWindowsTransfer"
        : "windowsToMacTransfer";
  showMoveSubscriptionForReinstall.value =
    (modalType.value === "windowsToMacTransfer" || modalType.value === "macToWindowsTransfer") && !isTrial.value;
}
checkMoveSubForReinstallRequired();

function toggleMoveMySubscriptionModal(type: string | null) {
  type = type || "modal";
  logEvent(
    "toggleMoveMySubscriptionModal",
    componentName,
    `${showMoveMySubscriptionModal.value ? "Hiding" : "Showing"} via ${type}`
  );
  showMoveMySubscriptionModal.value = !showMoveMySubscriptionModal.value;
}

function toggleDownloadProductModal(type: string | null, isCdlp: boolean) {
  type = type || "modal";
  logEvent(
    "toggleDownloadProductModal",
    componentName,
    `${showDownloadProductModal.value ? "Hiding" : "Showing"} via ${type}`
  );
  downloadModalCDLPReinstall.value = isCdlp;
  showDownloadProductModal.value = !showDownloadProductModal.value;
}

function toggleAddEncryptionKeyModal(type: string | null) {
  type = type || "modal";
  logEvent(
    "toggleAddEncryptionKeyModal",
    componentName,
    `${showAddEncryptionKeyModal.value ? "Hiding" : "Showing"} via ${type}`
  );
  showAddEncryptionKeyModal.value = !showAddEncryptionKeyModal.value;
}

function moveSubscription(installType: string) {
  try {
    logEvent("MoveMySubscription modal saved", componentName, installType);

    if (installType === "Transfer" || installType === "Recover") {
      emits("set-installer-type", props.device, installType);
    } else if (installType === "Reinstall") {
      //Close Move subscription modal
      toggleMoveMySubscriptionModal("moveSubscription");

      //Set install type
      typeOfInstall.value = installType;
      toggleDownloadProductModal("moveSubscription", false);
    }
  } catch (err) {
    handleApiError(err as AxiosError);
  }
}

function reinstallSafeClient(isCDLP: boolean) {
  //Set install type
  typeOfInstall.value = "Reinstall";
  if (showMoveSubscriptionForReinstall.value) {
    toggleMoveMySubscriptionModal("moveSubscription");
  } else {
    toggleDownloadProductModal("reinstallSafeClient", isCDLP);
  }
}

function buyNow(isRenew: boolean) {
  emits("buy-now", isRenew);
}

function changePlan() {
  emits("change-plan");
}

async function addVideo() {
  try {
    await unifiedApi.optInToAutomaticVideoBackup(props.device.deviceId);
    displayAddVideo.value = false;
    notificationsStore.addNotification({
      type: "OptInToAutomaticVideoBackupSuccess",
      params: { deviceName: props.device.name },
    });
  } catch (err) {
    handleApiError(err as AxiosError);
  }
}

function hideAddEncryptionKey() {
  displayAddEncryptionKey.value = false;
}
</script>
