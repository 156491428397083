import { iGroupedProducts } from "@/components/Buy/Interfaces";

export const groupedProducts: iGroupedProducts = {
  DUOAVPREMIUM: [
    { sku: "WebrootAntiVirus", months: 12, includedUnits: 5 },
    { sku: "WebrootPremium", months: 12, includedUnits: 5 },
  ],
  DUOAVAIP: [
    { sku: "WebrootAntiVirus", months: 12, includedUnits: 1 },
    { sku: "AllstateIdentityProtectionBasic", months: 12, includedIdentities: 1 },
  ],
  DUOAVPCO: [
    { sku: "WebrootAntiVirus", months: 12, includedUnits: 1 },
    { sku: "WebrootPcOptimizer", months: 12, includedUnits: 10 },
  ],
  DUOAVPCOVPN3: [
    { sku: "WebrootAntiVirus", months: 12, includedUnits: 1 },
    { sku: "WebrootPcOptimizer", months: 12, includedIdentities: 10 },
    { sku: "WebrootSecureVPNP", months: 12, includedUnits: 3 },
  ],
  DUOAVPCOID: [
    { sku: "WebrootAntiVirus", months: 12, includedUnits: 1 },
    { sku: "WebrootPcOptimizer", months: 12, includedUnits: 10 },
    { sku: "AllstateIdentityProtectionBasic", months: 12, includedIdentities: 1 },
  ],
  DUOPLUSPCO: [
    { sku: "WebrootPlus", months: 12, includedUnits: 3 },
    { sku: "WebrootPcOptimizer", months: 12, includedUnits: 10 },
  ],
  DUOPLUSPCOVPN3: [
    { sku: "WebrootPlus", months: 12, includedUnits: 3 },
    { sku: "WebrootPcOptimizer", months: 12, includedUnits: 10 },
    { sku: "WebrootSecureVPNP", months: 12, includedUnits: 3 },
  ],
  DUOPLUSPCOID: [
    { sku: "WebrootPlus", months: 12, includedUnits: 3 },
    { sku: "WebrootPcOptimizer", months: 12, includedUnits: 10 },
    { sku: "AllstateIdentityProtectionBasic", months: 12, includedIdentities: 1 },
  ],
  DUOPREMIUMPCO: [
    { sku: "WebrootPremium", months: 12, includedUnits: 5 },
    { sku: "WebrootPcOptimizer", months: 12, includedUnits: 10 },
  ],
  DUOPREMIUMPCOVPN5: [
    { sku: "WebrootPremium", months: 12, includedUnits: 5 },
    { sku: "WebrootPcOptimizer", months: 12, includedUnits: 10 },
    { sku: "WebrootSecureVPNP", months: 12, includedUnits: 5 },
  ],
  DUOFAMILYPCO: [
    { sku: "WebrootPremiumFamily", months: 12, includedUnits: 10 },
    { sku: "WebrootPcOptimizer", months: 12, includedUnits: 10 },
  ],
  DUOFAMILYPCOVPN10: [
    { sku: "WebrootPremiumFamily", months: 12, includedUnits: 10 },
    { sku: "WebrootPcOptimizer", months: 12, includedUnits: 10 },
    { sku: "WebrootSecureVPNP", months: 12, includedUnits: 10 },
  ],
  DUOAVVPN3: [
    { sku: "WebrootAntiVirus", months: 12, includedUnits: 1 },
    { sku: "WebrootSecureVPNP", months: 12, includedUnits: 3 },
  ],
  DUOAVIDVPN3: [
    { sku: "WebrootAntiVirus", months: 12, includedUnits: 1 },
    { sku: "AllstateIdentityProtectionBasic", months: 12, includedIdentities: 1 },
    { sku: "WebrootSecureVPNP", months: 12, includedUnits: 3 },
  ],
  DUOPREMIUMVPN5: [
    { sku: "WebrootPremium", months: 12, includedUnits: 5 },
    { sku: "WebrootSecureVPNP", months: 12, includedUnits: 5 },
  ],
  DUOFAMILYVPN10: [
    { sku: "WebrootPremiumFamily", months: 12, includedUnits: 10 },
    { sku: "WebrootSecureVPNP", months: 12, includedUnits: 10 },
  ],

  DUOPLUSVPN: [
    { sku: "WebrootPlus", months: 12, includedUnits: 3 },
    { sku: "WebrootSecureVPNP", months: 12, includedUnits: 3 },
  ],
  DUOCOMPLETEVPN: [
    { sku: "WebrootComplete", months: 12, includedUnits: 5 },
    { sku: "WebrootSecureVPNP", months: 12, includedUnits: 5 },
  ],
  DUOAVAIP1: [
    { sku: "WebrootAntiVirus", months: 12, includedUnits: 1 },
    { sku: "AllstateIdentityProtectionBasic", months: 12, includedIdentities: 1 },
  ],
  DUOESSVPN1: [
    { sku: "WebrootEssentials", months: 12, includedUnits: 1 },
    { sku: "WebrootSecureVPNP", months: 12, includedUnits: 3 },
  ],
  DUOESSVPN3: [
    { sku: "WebrootEssentials", months: 12, includedUnits: 3 },
    { sku: "WebrootSecureVPNP", months: 12, includedUnits: 3 },
  ],
  DUOESSVPN5: [
    { sku: "WebrootEssentials", months: 12, includedUnits: 5 },
    { sku: "WebrootSecureVPNP", months: 12, includedUnits: 5 },
  ],

  DUOESSIDVPN5: [
    { sku: "WebrootEssentials", months: 12, includedUnits: 5 },
    { sku: "AllstateIdentityProtectionBasic", months: 12, includedIdentities: 1 },
    { sku: "WebrootSecureVPNP", months: 12, includedUnits: 5 },
  ],
  DUOESSID: [
    { sku: "WebrootEssentials", months: 12, includedUnits: 1 },
    { sku: "AllstateIdentityProtectionBasic", months: 12, includedIdentities: 1 },
  ],
  DUOESSPCO: [
    { sku: "WebrootEssentials", months: 12, includedUnits: 1 },
    { sku: "WebrootPcOptimizer", months: 12, includedUnits: 10 },
  ],
  DUOESSPCOID: [
    { sku: "WebrootEssentials", months: 12, includedUnits: 1 },
    { sku: "AllstateIdentityProtectionBasic", months: 12, includedIdentities: 1 },
    { sku: "WebrootPcOptimizer", months: 12, includedUnits: 10 },
  ],
  DUOESSPCOVPN: [
    { sku: "WebrootEssentials", months: 12, includedUnits: 1 },
    { sku: "WebrootSecureVPNP", months: 12, includedUnits: 3 },
    { sku: "WebrootPcOptimizer", months: 12, includedUnits: 10 },
  ],
  DUOESS3PCO: [
    { sku: "WebrootEssentials", months: 12, includedUnits: 3 },
    { sku: "WebrootPcOptimizer", months: 12, includedUnits: 10 },
  ],
  DUOESS3PCOVPN: [
    { sku: "WebrootEssentials", months: 12, includedUnits: 3 },
    { sku: "WebrootSecureVPNP", months: 12, includedUnits: 3 },
    { sku: "WebrootPcOptimizer", months: 12, includedUnits: 10 },
  ],
  DUOWTP5PCO: [
    { sku: "WebrootTotalProtection", months: 12, includedUnits: 5 },
    { sku: "WebrootPcOptimizer", months: 12, includedUnits: 10 },
  ],
  DUOWTP10PCO: [
    { sku: "WebrootTotalProtectionFamily", months: 12, includedUnits: 10 },
    { sku: "WebrootPcOptimizer", months: 12, includedUnits: 10 },
  ],
};
