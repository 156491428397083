<template>
  <div v-if="showSpinner" class="payment-history">
    <LoadingSpinner />
  </div>
  <div v-else class="payment-history">
    <div class="content">
      <PrimaryEmailVerificationWrapperComponent notification-context="PaymentHistoryComponent" :email="email" />
      <h1 class="h1">{{ tn("paymentHistory") }}</h1>
      <div class="table-to-list-wrapper">
        <table v-if="payments.length > 0">
          <thead>
            <tr>
              <th class="sortable" :class="sortDirection.invoiceDate" @click="sortPayments('invoiceDate')">
                {{ tn("dateCol") }}
              </th>
              <th class="sortable" :class="sortDirection.invoiceId" @click="sortPayments('invoiceId')">
                {{ tn("invoiceCol") }}
              </th>
              <th class="sortable" :class="sortDirection.productNameSort" @click="sortPayments('productNameSort')">
                {{ tn("productCol") }}
              </th>
              <th class="sortable" :class="sortDirection.creditCardType" @click="sortPayments('creditCardType')">
                {{ tn("creditCardCol") }}
              </th>
              <th
                class="sortable"
                :class="sortDirection.invoicePaymentAmount"
                @click="sortPayments('invoicePaymentAmount')"
              >
                {{ tn("total") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(invoice, i) in payments" :key="i" class="mb-double">
              <td :data-th="tn('dateCol')">
                <span>{{ formatPaymentDate(invoice) }}</span>
              </td>
              <td :data-th="tn('invoiceCol')">
                <span class="block">{{ invoice.invoiceId }}</span>
                <span>
                  <button
                    v-if="invoice.canRefundWebroot && FLAGS.ENABLE_BUYFLOW_WEBROOT && !isUnderMaintenance"
                    type="button"
                    class="inline-btn-link"
                    @click="requestRefund(invoice)"
                  >
                    {{ tn("requestRefund") }}
                  </button>
                </span>
                <span>
                  <button type="button" class="inline-btn-link" @click="toggleDisplayInvoice(invoice.invoiceId)">
                    {{ tn("viewPrint") }}
                  </button>
                </span>
              </td>
              <td :data-th="tn('productCol')">
                <SafeInvoiceProductInfo
                  v-if="hasSafeItems(invoice.subscriptionPaymentItems)"
                  :invoice="invoice"
                  :localized-string-component-name="componentName"
                />
                <WebrootInvoiceProductInfo v-else :invoice="invoice" :localized-string-component-name="componentName" />
              </td>
              <td :data-th="tn('creditCardCol')">
                <span v-if="hideCreditCardInfo(invoice)">{{ tn("notApplicable") }}</span>
                <span v-else>{{ creditCardInfo(invoice) }}</span>
              </td>
              <td :data-th="tn('total')">{{ formatInvoiceAmount(invoice) }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="payments.length === 0" class="message-box">
        {{ tn("noInvoicesToDisplay") }}
      </div>
      <section v-if="creditBalance">
        <header>
          <h1>{{ tn("accountCredit") }}</h1>
        </header>
        <i18n-t keypath="PaymentHistoryComponent.amountOfCredit" tag="p" class="mb-double" scope="global">
          <template #amount>
            <strong>{{ formatMoney(creditBalance) }}</strong>
          </template>
        </i18n-t>
      </section>
      <p class="mb-double">{{ tn("otherTransactionNotice") }}</p>
      <InPlaceCdlpRefundConfirmationComponent
        :invoice="selectedInvoice"
        :show-dialog="showInPlaceCdlpRefundConfirmationDialog"
        @close-modal="closeInPlaceCdlpRefundConfirmationDialog()"
        @refund-purchase="goToWebrootRefundUrl(selectedInvoice)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, onMounted } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { unifiedApi } from "@/common";
import {
  IUserPaymentHistory,
  IUserPaymentHistoryPaymentInfo,
  IUserPaymentHistoryPaymentInfoItems,
} from "@/common/api/unifiedPortal/interfaces";
import { useUserStore } from "@/stores/user";
import PrimaryEmailVerificationWrapperComponent from "@/components/shared/PrimaryEmailVerificationWrapper.vue";
import LoadingSpinner from "@/components/shared/LoadingSpinner.vue";
import { handleApiError } from "@/common/handleApiError";
import { AxiosError } from "axios";
import { useNotificationsStore } from "@/stores/notifications";
import SafeInvoiceProductInfo from "@/components/payment/SafeInvoiceProductInfo.vue";
import WebrootInvoiceProductInfo from "@/components/payment/WebrootInvoiceProductInfo.vue";
import { formatDate } from "@/common/dateFormat";
import { processData } from "@/components/payment/PaymentHistoryPageHelper";
import { carboniteProducts } from "@/common/productsBySku";
import { FLAGS, PAYPAL_CARD_TYPE, WEBROOT_TOTAL_PROTECTION, WEBROOT_TOTAL_PROTECTION_FAMILY } from "@/define";
import { getUrl } from "@/common/getUrl";
import { useRouter } from "vue-router";
import InPlaceCdlpRefundConfirmationComponent from "@/components/shared/dialogs/RefundCdlpConfirmation/InPlaceCdlpRefundConfirmation.vue";

const componentName = ref<string>("PaymentHistoryComponent");

logEvent("created", componentName.value);

const userStore = useUserStore();
const notificationsStore = useNotificationsStore();
const user = computed(() => userStore.currentUser);
const creditBalance = ref<number>(0);
const paymentHistory = ref<IUserPaymentHistory | null>(null);
const payments = ref<IUserPaymentHistoryPaymentInfo[]>([]);
const showSpinner = ref(true);
const sortColumn = ref<string>("");
const email = ref<string>("");
const router = useRouter();
const sortDirection = ref<Record<string, unknown>>({
  invoiceDate: "ascend",
  invoiceId: "",
  productNameSort: "",
  creditCardType: "",
  invoicePaymentAmount: "",
});
const isUnderMaintenance = computed(() => {
  return !!user.value?.subscriberInfo?.isUnderMaintenance;
});
const selectedInvoice = ref<IUserPaymentHistoryPaymentInfo>();
const showInPlaceCdlpRefundConfirmationDialog = ref<boolean>(false);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName.value}.${v}`, params);
}

onMounted(async () => {
  logEvent("mounted", componentName.value);
  email.value = user.value?.email ?? "";

  if (user.value?.subscriberInfo?.hasPendingWebrootLicenses && !user.value?.subscriberInfo?.hasValidatedEmail) {
    notificationsStore.addNotification({ type: "PrimaryEmailVerificationWebroot" });
  }
  try {
    if (user.value) {
      const response = (await unifiedApi.getUserPaymentHistory()).data;
      if (response) {
        paymentHistory.value = response;
        if (paymentHistory.value) {
          creditBalance.value = paymentHistory.value.creditBalance;
          payments.value = processData(paymentHistory.value.payments, componentName.value);
        }
        sortPayments("invoiceDate");
      } else {
        logEvent(`getUserPaymentHistory result NULL`, `${componentName.value}/onMounted`);
      }
    } else {
      logEvent(`user value NULL`, `${componentName.value}/onMounted`);
    }
  } catch (err) {
    handleApiError(err as AxiosError, true);
  } finally {
    showSpinner.value = false;
  }
});

function hasSafeItems(invoiceItems: IUserPaymentHistoryPaymentInfoItems[]) {
  let result = false;
  invoiceItems.forEach(item => {
    const productCheck = carboniteProducts.some(p => p.sku === item.sku);
    if (productCheck) {
      result = true;
    }
  });

  return result;
}

function formatPaymentDate(invoice: IUserPaymentHistoryPaymentInfo) {
  return formatDate(new Date(invoice.invoiceDate));
}

function creditCardInfo(invoice: IUserPaymentHistoryPaymentInfo) {
  const fourDigit = invoice.creditCardFourDigit ? `*${invoice.creditCardFourDigit}` : "";
  return `${invoice.creditCardType}${fourDigit}`;
}

function hideCreditCardInfo(invoice: IUserPaymentHistoryPaymentInfo) {
  return !invoice.creditCardType || (!invoice.creditCardFourDigit && invoice.creditCardType != PAYPAL_CARD_TYPE);
}

function formatInvoiceAmount(invoice: IUserPaymentHistoryPaymentInfo) {
  return new Number(invoice.invoicePaymentAmount).toLocaleString("en-US", { style: "currency", currency: "USD" });
}

function formatMoney(amount: number) {
  return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(amount);
}

function requestRefund(invoice: IUserPaymentHistoryPaymentInfo) {
  if (
    invoice.currentFruidSku === WEBROOT_TOTAL_PROTECTION ||
    invoice.currentFruidSku === WEBROOT_TOTAL_PROTECTION_FAMILY
  ) {
    selectedInvoice.value = invoice;
    showInPlaceCdlpRefundConfirmationDialog.value = true;
  } else {
    goToWebrootRefundUrl(invoice);
  }
}

function goToWebrootRefundUrl(invoice: IUserPaymentHistoryPaymentInfo | undefined) {
  //pass invoice number (invoiceId) and total (invoicePaymentAmount) as query parameters
  window.open(
    getUrl("WEBROOT_REQUEST_A_REFUND")
      .replace("{invoiceId}", invoice?.invoiceId)
      .replace("{invoicePaymentAmount}", invoice?.invoicePaymentAmount),
    "_blank"
  );
}

function sortPayments(field: string) {
  //turn off sorting indicator on old column
  if (sortColumn.value !== field) sortDirection.value[sortColumn.value] = "";

  //pick a direction for the sort
  if (sortDirection.value[field] == "ascend") sortDirection.value[field] = "descend";
  else if (sortDirection.value[field] == "descend") sortDirection.value[field] = "ascend";
  else sortDirection.value[field] = "ascend";

  const order = sortDirection.value[field] === "ascend" ? -1 : 1;

  if (payments.value)
    payments.value = payments.value.sort((a: IUserPaymentHistoryPaymentInfo, b: IUserPaymentHistoryPaymentInfo) => {
      if (a[field] > b[field]) return order;
      if (a[field] < b[field]) return -order;
      return 0;
    });
  sortColumn.value = field;
}

function toggleDisplayInvoice(invoiceNumber: string | null) {
  logEvent(`${invoiceNumber}`, "in toggle fn");

  var url = router.resolve({ path: `/invoice/${invoiceNumber}` });

  window.open(url.href, "_blank");
}

function closeInPlaceCdlpRefundConfirmationDialog() {
  showInPlaceCdlpRefundConfirmationDialog.value = false;
}
</script>
