export interface IStateAndProvinceItem {
  value: string;
  text: string;
}

export interface IShippingStatesAndProvinces {
  "United States": IStateAndProvinceItem[];
  "Australia": IStateAndProvinceItem[];
  "Canada": IStateAndProvinceItem[];
  "Japan": IStateAndProvinceItem[];
  "New Zealand": IStateAndProvinceItem[];
}

export interface country {
  code: string;
  name: string;
}

export const statesAndProvincesCountryCodes: country[] = [
  { code: "US", name: "United States" },
  { code: "AU", name: "Australia" },
  { code: "CA", name: "Canada" },
  { code: "JA", name: "Japan" },
  { code: "NZ", name: "New Zealand" },
];

export const shippingStatesAndProvinces: IShippingStatesAndProvinces = {
  "United States": [
    { value: "AL", text: "Alabama" },
    { value: "AK", text: "Alaska" },
    { value: "AS", text: "American Samoa" },
    { value: "AZ", text: "Arizona" },
    { value: "AR", text: "Arkansas" },
    { value: "CA", text: "California" },
    { value: "CO", text: "Colorado" },
    { value: "CT", text: "Connecticut" },
    { value: "DE", text: "Delaware" },
    { value: "DC", text: "District of Columbia" },
    { value: "FL", text: "Florida" },
    { value: "GA", text: "Georgia" },
    { value: "GU", text: "Guam" },
    { value: "HI", text: "Hawaii" },
    { value: "ID", text: "Idaho" },
    { value: "IL", text: "Illinois" },
    { value: "IN", text: "Indiana" },
    { value: "IA", text: "Iowa" },
    { value: "KS", text: "Kansas" },
    { value: "KY", text: "Kentucky" },
    { value: "LA", text: "Louisiana" },
    { value: "ME", text: "Maine" },
    { value: "MD", text: "Maryland" },
    { value: "MA", text: "Massachusetts" },
    { value: "MI", text: "Michigan" },
    { value: "MN", text: "Minnesota" },
    { value: "MS", text: "Mississippi" },
    { value: "MO", text: "Missouri" },
    { value: "MT", text: "Montana" },
    { value: "NE", text: "Nebraska" },
    { value: "NV", text: "Nevada" },
    { value: "NH", text: "New Hampshire" },
    { value: "NJ", text: "New Jersey" },
    { value: "NM", text: "New Mexico" },
    { value: "NY", text: "New York" },
    { value: "NC", text: "North Carolina" },
    { value: "ND", text: "North Dakota" },
    { value: "MP", text: "Northern Mariana Islands" },
    { value: "OH", text: "Ohio" },
    { value: "OK", text: "Oklahoma" },
    { value: "OR", text: "Oregon" },
    { value: "PA", text: "Pennsylvania" },
    { value: "PR", text: "Puerto Rico" },
    { value: "RI", text: "Rhode Island" },
    { value: "SC", text: "South Carolina" },
    { value: "SD", text: "South Dakota" },
    { value: "TN", text: "Tennessee" },
    { value: "TX", text: "Texas" },
    { value: "UT", text: "Utah" },
    { value: "VT", text: "Vermont" },
    { value: "VI", text: "Virgin Islands" },
    { value: "VA", text: "Virginia" },
    { value: "WA", text: "Washington" },
    { value: "WV", text: "West Virginia" },
    { value: "WI", text: "Wisconsin" },
    { value: "WY", text: "Wyoming" },
  ],
  "Australia": [
    { value: "ACT", text: "Australian Capital Territory" },
    { value: "NSW", text: "New South Wales" },
    { value: "NT", text: "Northern Territory" },
    { value: "QLD", text: "Queensland" },
    { value: "SA", text: "South Australia" },
    { value: "TAS", text: "Tasmania" },
    { value: "VIC", text: "Victoria" },
    { value: "WA", text: "Western Australia" },
  ],
  "Canada": [
    { value: "AB", text: "Alberta" },
    { value: "BC", text: "British Columbia" },
    { value: "MB", text: "Manitoba" },
    { value: "NB", text: "New Brunswick" },
    { value: "NL", text: "Newfoundland" },
    { value: "NT", text: "Northwest Territories" },
    { value: "NS", text: "Nova Scotia" },
    { value: "NU", text: "Nunavut" },
    { value: "ON", text: "Ontario" },
    { value: "PE", text: "Prince Edward Island" },
    { value: "QC", text: "Quebec" },
    { value: "SK", text: "Saskatchewan" },
    { value: "YT", text: "Yukon Territory" },
  ],
  "Japan": [
    { value: "0", text: "都道府県の選択" },
    { value: "1", text: "北海道" },
    { value: "2", text: "青森県" },
    { value: "3", text: "岩手県" },
    { value: "4", text: "宮城県" },
    { value: "5", text: "秋田県" },
    { value: "6", text: "山形県" },
    { value: "7", text: "福島県" },
    { value: "8", text: "茨城県" },
    { value: "9", text: "栃木県" },
    { value: "10", text: "群馬県" },
    { value: "11", text: "埼玉県" },
    { value: "12", text: "千葉県" },
    { value: "13", text: "東京都" },
    { value: "14", text: "神奈川県" },
    { value: "15", text: "新潟県" },
    { value: "16", text: "富山県" },
    { value: "17", text: "石川県" },
    { value: "18", text: "福井県" },
    { value: "19", text: "山梨県" },
    { value: "20", text: "長野県" },
    { value: "21", text: "岐阜県" },
    { value: "22", text: "静岡県" },
    { value: "23", text: "愛知県" },
    { value: "24", text: "三重県" },
    { value: "25", text: "滋賀県" },
    { value: "26", text: "京都府" },
    { value: "27", text: "大阪府" },
    { value: "28", text: "兵庫県" },
    { value: "29", text: "奈良県" },
    { value: "30", text: "和歌山県" },
    { value: "31", text: "鳥取県" },
    { value: "32", text: "島根県" },
    { value: "33", text: "岡山県" },
    { value: "34", text: "広島県" },
    { value: "35", text: "山口県" },
    { value: "36", text: "徳島県" },
    { value: "37", text: "香川県" },
    { value: "38", text: "愛媛県" },
    { value: "39", text: "高知県" },
    { value: "40", text: "福岡県" },
    { value: "41", text: "佐賀県" },
    { value: "42", text: "長崎県" },
    { value: "43", text: "熊本県" },
    { value: "44", text: "大分県" },
    { value: "45", text: "宮崎県" },
    { value: "46", text: "鹿児島県" },
    { value: "47", text: "沖縄県" },
  ],
  "New Zealand": [
    { value: "AUK", text: "Auckland" },
    { value: "BOP", text: "Bay of Plenty" },
    { value: "CAN", text: "Canterbury" },
    { value: "GIS", text: "Gisborne" },
    { value: "HKB", text: "Hawke's Bay" },
    { value: "MBH", text: "Marlborough" },
    { value: "MWT", text: "Manawatu-Wanganui" },
    { value: "NSN", text: "Nelson" },
    { value: "NTL", text: "Northland" },
    { value: "OTA", text: "Otago" },
    { value: "STL", text: "Southland" },
    { value: "TAS", text: "Tasman" },
    { value: "TKI", text: "Taranaki" },
    { value: "WKO", text: "Waikato" },
    { value: "WGN", text: "Wellington" },
    { value: "WTC", text: "West Coast" },
    { value: "CIT", text: "Chatham Islands Territory" },
  ],
};
