<template>
  <header>
    <h1>
      <button id="btnReturnToBackup" class="inline-btn-link" type="button" @click="returnToBackup()">
        <svg-icon-component icon="chevron-left" class="icon" />
      </button>
      {{ tn("header") }}
    </h1>
  </header>
  <section v-if="componentState == upgradeSubscriptionComponentState.default">
    <p>{{ tn("choose") }}</p>
    <div class="table-to-list-wrapper">
      <table id="tableSubscriptionInformation">
        <thead>
          <tr>
            <th>{{ tn("computerCol") }}</th>
            <th>{{ tn("planCol") }}</th>
            <th>{{ tn("expirationCol") }}</th>
            <th>{{ tn("statusCol") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="device in visibleDevices" :key="device.deviceId">
            <td :data-th="tn('computerCol')">
              <div class="radio">
                <input
                  :id="`computer${device.deviceId}`"
                  v-model="selectedComputer"
                  type="radio"
                  :value="device.deviceId"
                />
                <label :for="`computer${device.deviceId}`">{{ formatComputerName(device.description) }}</label>
              </div>
            </td>
            <td :data-th="tn('planCol')">{{ t(getProductName(device)) }}</td>
            <td :data-th="tn('expirationCol')">{{ formatExpirationDate(device.expirationDate) }}</td>
            <td :data-th="tn('statusCol')">
              <svg-icon-component
                v-if="calculateComputerStatusIcon(device) == 'danger'"
                class="icon-sm danger"
                icon="circle-exclamation"
              />
              <svg-icon-component
                v-if="calculateComputerStatusIcon(device) == 'warning'"
                class="icon-sm warning"
                icon="circle-triangle"
              />
              {{ calculateComputerStatus(device) }}
            </td>
          </tr>
          <tr>
            <td colspan="4" class="no-marker">
              <div class="radio">
                <input id="computer-add" v-model="selectedComputer" type="radio" value="add" />
                <label for="computer-add">{{ tn("addNewSub") }}</label>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <footer>
      <button id="StartNext" type="button" class="btn-primary" :disabled="!selectedComputer" @click="defaultNext()">
        {{ tn("next") }}
      </button>
    </footer>
  </section>
  <section v-if="componentState == upgradeSubscriptionComponentState.floatingSub">
    <ApplyFloatingSubscriptionComponent
      :devices="props.devices"
      :user-id="props.userId"
      @buy-new="buyNew"
      @ac-applied="showACAppliedResult"
      @install="install"
      @cancel="returnToBackup"
    />
  </section>
</template>

<script setup lang="ts">
import { PropType, ref } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { IDevice, ISubscription } from "@/common/api/unifiedPortal/interfaces";
import { SUBSCRIPTION_MIN_EXPIRED_DAY, SUBSCRIPTION_MIN_WARNING_DAY } from "@/define";
import { formatDateString } from "@/common/dateFormat";
import { isPurgedComputer } from "@/components/Backup/purgedComputer";
import { getProductDisplayNameBySku, IProductDisplayRequest } from "@/common/productsBySku";
import ApplyFloatingSubscriptionComponent from "./ApplyFloatingSubscription.vue";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { formatComputerName } from "@/common/formatComputerName";
import { useRouter } from "vue-router";
import { useBuyFlowStore } from "@/stores/buyFlow";
import { canDisplayPerSubscriptionFeature, perSubscriptionFeatures } from "@/common/featureDisplayAccess";

enum upgradeSubscriptionComponentState {
  default,
  floatingSub,
}

const name = "UpgradeSubscriptionComponent";

const props = defineProps({
  devices: {
    type: Array as PropType<IDevice[]>,
    required: true,
  },
  userId: {
    type: Number,
    required: true,
  },
  subscriptions: {
    type: Object as PropType<ISubscription[]>,
    required: false,
    default: null,
  },
});

const emits = defineEmits(["action", "show-invoice-details", "action", "install-ac"]);

const selectedComputer = ref<string>("");
const componentState = ref<upgradeSubscriptionComponentState>(upgradeSubscriptionComponentState.default);
const visibleDevices = ref<IDevice[]>([]);
const router = useRouter();
const buyFlowStore = useBuyFlowStore();

logEvent("created", name, props.devices);

const hasFloatingSub = props.devices.find(c => c.deviceId === 0);
if (hasFloatingSub) {
  componentState.value = upgradeSubscriptionComponentState.floatingSub;
}
visibleDevices.value = props.devices.filter(
  c => !isPurgedComputer(c.daysRemaining, c.isTrial, false, c.dataHasBeenPurged, c.isEvergreenMonthly)
);

//Filter visible devices again for the retail codes
visibleDevices.value = visibleDevices.value.filter(l =>
  canDisplayPerSubscriptionFeature(
    perSubscriptionFeatures.changePlan,
    getSubscriptionByDevice(l)?.carbLicenseDistributionMethodCode || ""
  )
);

logEvent("VisibleDevices", name, visibleDevices.value);

function getSubscriptionByDevice(device: IDevice) {
  return props.subscriptions.find(l => l.computer?.id === device.deviceId);
}

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${name}.${v}`, params);
}

function getProductName(device: IDevice) {
  const request: IProductDisplayRequest = {
    sku: device.sku,
    webrootKeycode: device.webrootKeycode,
    isTrial: device.isTrial,
  };
  return getProductDisplayNameBySku(request);
}

function returnToBackup() {
  componentState.value = upgradeSubscriptionComponentState.default;
  emits("action", "close");
}

function defaultNext() {
  const selectedDeviceWithOutstandingInvoice = visibleDevices.value.find(
    c => c.deviceId === parseInt(selectedComputer.value) && c.hasOutstandingInvoice
  );
  if (selectedDeviceWithOutstandingInvoice) {
    emits("show-invoice-details", selectedComputer.value);
    return;
  }
  buyNew();
}

async function buyNew() {
  // We need to clear the cart if one already exists, so we can start over.
  await buyFlowStore.clearCart();

  if (selectedComputer.value === "add") {
    router.push({ name: "selectPlan" });
  } else {
    router.push({
      name: "selectPlan",
      query: {
        deviceId: selectedComputer.value,
      },
    });
  }
}

function formatExpirationDate(expirationDate: string) {
  return formatDateString(expirationDate);
}

function calculateComputerStatus(device: IDevice): string {
  if (device.autoRenewInfo) {
    return tn("activeAutoRenewal");
  }

  if (device.isTrial) {
    let expired = "";
    if (device.daysRemaining <= SUBSCRIPTION_MIN_EXPIRED_DAY) {
      expired = tn("trialExpired");
    }
    return `${tn("trial")}${expired}`;
  }

  if (device.isEvergreenMonthly) {
    return tn("activeUntilCancelled");
  }

  if (device.daysRemaining <= SUBSCRIPTION_MIN_EXPIRED_DAY) {
    return tn("expired");
  }

  if (device.daysRemaining <= SUBSCRIPTION_MIN_WARNING_DAY) {
    return device.daysRemaining === 1 ? tn("expiresIn1day") : `${tn("expiresIn")}${device.daysRemaining}${tn("days")}`;
  }

  return tn("active");
}

function calculateComputerStatusIcon(device: IDevice): string {
  return device.daysRemaining <= SUBSCRIPTION_MIN_EXPIRED_DAY
    ? "danger"
    : device.daysRemaining <= SUBSCRIPTION_MIN_WARNING_DAY && !device.autoRenewInfo
      ? "warning"
      : "";
}

function showACAppliedResult(status: boolean) {
  emits("action", "acApplied", status);
}

function install(device: IDevice) {
  emits("install-ac", device, "Install");
  logEvent("InstallAC event emitted", name, device);
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
@import "@/styles/variables.css";
</style>
