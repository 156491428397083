<template>
  <article class="constrain-90 m-auto mt-double mb-200">
    <h1 class="mb">{{ tn("header") }}</h1>
    <p>{{ tn("p1") }}</p>
    <p>{{ tn("p2") }}</p>
    <p class="mb">
      <button type="button" class="btn-primary mobile-text" @click="goToSubscriptionsTab()">
        {{ tn("buttonGoToMyAccount") }}
      </button>
    </p>
  </article>
</template>

<script setup lang="ts">
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import router from "@/routes";

const componentName = "RenewCuidAlreadyPresentForWtpComponent";
logEvent("created", componentName);
function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

function goToSubscriptionsTab() {
  router.push({ path: "/subscriptions" });
}
</script>
