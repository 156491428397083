import { getCarboniteProductTName } from "@/i18n/brandMapper";
import { logEvent } from "@/common/logger";
export const carboniteProducts: ICarboniteProduct[] = [
  { sku: "PersonalBasic", upgradeOrder: 1, features: ["featurePersonalBasic1"] },
  { sku: "CarboniteHome", upgradeOrder: 1, features: ["featurePersonalBasic1"] },
  {
    sku: "PersonalPlus",
    upgradeOrder: 2,
    features: ["featurePersonalPlus1", "featurePersonalPlus2", "featurePersonalPlus3"],
  },
  {
    sku: "PersonalPrime",
    upgradeOrder: 3,
    features: ["featurePersonalPrime1", "featurePersonalPrime2"],
  },
  {
    sku: "CarboniteHomePlus",
    upgradeOrder: 2,
    features: ["featurePersonalPlus1", "featurePersonalPlus2", "featurePersonalPlus3"],
  },
  {
    sku: "CarboniteHomePremier",
    upgradeOrder: 3,
    features: ["featurePersonalPrime1", "featurePersonalPrime2"],
  },
];

export const carboniteSkus = [
  "PersonalBasic",
  "PersonalPlus",
  "PersonalPrime",
  "CarboniteHomePlus",
  "CarboniteHomePremier",
];
export function getProductDisplayNameBySku(request: IProductDisplayRequest) {
  logEvent("getProductDisplayNameBySku", JSON.stringify(request));
  if (!request.sku) {
    return "";
  }

  // Fix to get the correct product name for StandAlone Webroot SKU
  // TODO: Remove this when we start to sell Webroot in Zuora
  if (request.sku === "Webroot") request.sku = "WSAV";

  if (carboniteProducts.some(product => product.sku === request.sku)) {
    if (
      request.sku === "PersonalBasic" &&
      !!request.webrootKeycode &&
      !request.isTrial &&
      request.zuoraSubscriptionStatus !== "Cancelled"
    ) {
      return "Brand.PersonalLite";
    } else {
      return `Brand.${getCarboniteProductMapper(request.sku)}`;
    }
  } else {
    return `Brand.${request.sku}`;
  }
}

export function getCarboniteProductMapper(sku: string): string {
  return getCarboniteProductTName(sku);
}

interface ICarboniteProduct {
  sku: string;
  upgradeOrder: number;
  features?: string[];
}
export interface IProductDisplayRequest {
  sku?: string;
  webrootKeycode?: string;
  isTrial: boolean;
  zuoraSubscriptionStatus?: string;
}
